import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import ModalBody from '@components/Modals/components/ModalBody';
import ModalBreadcrumb from '@components/Modals/components/ModalBreadcrumb';
import ModalContent from '@components/Modals/components/ModalContent';
import ModalHeader from '@components/Modals/components/ModalHeader';
import ContentCarousel from '@components/UI/ContentCarousel';
import SelectTransactionAmount from '@components/Modals/components/SelectTransactionAmount';
import completePaypalDeposit from '@api/payment/completePaypalDeposit';
import PaymentMethod from '@components/Modals/AddFunds/components/PaymentMethod';
import Complete from '@components/Modals/AddFunds/components/Complete';
const useStyles = makeStyles()((theme) => ({
    closeButton: {
        height: '30px',
    },
    icon: {
        display: 'flex',
        flexDirection: 'column',
        '& img': {
            height: '20px',
            marginBottom: theme.spacing(1),
        },
    },
    label: {
        display: 'inline-flex',
        '& img': {
            height: '14px',
            marginRight: theme.spacing(0.5),
        },
    },
}));
const DepositCash = ({ onClose, onResetModal, preSelectedCurrency = false }) => {
    const { classes } = useStyles();
    const [breadcrumbIndex, setBreadcrumbIndex] = useState(1);
    const [transactionAmount, setTransactionAmount] = useState(0);
    const [paymentStatus, setPaymentStatus] = useState('WAITING');
    const [error, setError] = useState({
        errorMessage: 'Something went wrong with your payment.',
        allowRetry: true,
    });
    const [paypalOrderId, setPaypalOrderId] = useState(undefined);
    const hideCrumbs = preSelectedCurrency ? [0] : [];
    const breadcrumbs = [
        {
            id: '0',
            component: 'Select Currency',
            onClick: () => onResetModal(),
        },
        {
            id: '1',
            component: 'Amount',
            onClick: () => {
                setBreadcrumbIndex(1);
                setTransactionAmount(0);
                setPaymentStatus('WAITING');
            },
        },
        {
            id: '2',
            component: 'Payment Method',
            onClick: () => {
                if (transactionAmount) {
                    setBreadcrumbIndex(2);
                }
            },
        },
    ];
    const handleClose = () => {
        if (paypalOrderId && paymentStatus === 'WAITING') {
            // If the user closed the whole modal when there was a paypal order ID but the state suggests
            // nothing is completed or in process, cancel the payment_order.
            // Best effort - kick it off and ignore any errors.
            completePaypalDeposit({
                paypalOrderId,
                newOrderState: 'cancelled',
            });
        }
        onClose();
    };
    const handleCashSelection = (value) => {
        setTransactionAmount(value);
        setBreadcrumbIndex(2);
    };
    const handlePaymentMethod = () => {
        setBreadcrumbIndex(4);
    };
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { title: "Deposit Cash", subtitle: "Add cash to your Account", icon: _jsx("img", { src: "/img/icons/dollar.svg", alt: "dollar", className: classes.closeButton }), onClose: handleClose }), _jsx(ModalBreadcrumb, { currentIndex: breadcrumbIndex, breadcrumbs: breadcrumbs, hideCrumbs: hideCrumbs, isVisible: true }), _jsx(ModalBody, { children: _jsxs(ContentCarousel, { currentStep: breadcrumbIndex - 1, name: "Deposit Cash", children: [_jsx(ModalContent, { children: _jsx(SelectTransactionAmount, { minAmount: 10, amountsArray: [10, 20, 50, 100, 500], onAmountSelected: handleCashSelection, palette: "primary" }) }), _jsx(PaymentMethod, { onError: handlePaymentMethod, onSuccess: handlePaymentMethod, setError: setError, setPaymentStatus: setPaymentStatus, setPaypalOrderId: setPaypalOrderId, paypalOrderId: paypalOrderId, currencyAmount: transactionAmount, currency: "USD", transactionAmount: transactionAmount, paymentStatus: paymentStatus }), _jsx(Complete, { onClose: onClose, onRetry: () => setBreadcrumbIndex(2), paymentStatus: paymentStatus, currency: "USD", transactionAmount: transactionAmount, currencyAmount: transactionAmount, error: error })] }) })] }));
};
export default DepositCash;
