import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ModalContent from '@components/Modals/components/ModalContent';
import Choice from '@components/UI/Choice/Choice';
import { useEffect } from 'react';
import { useAddGameContext } from '../context/AddGameProvider';
import { setCurrentStepAction, setIsLoadingAction } from '../context/AddGameStepsActions';
import isRegion from '../helpers/isRegion';
export const ContentCODRegions = ({ className, onRegionSelected, stepName }) => {
    const { dispatch, connectPlatform, currentStep, connectGameRegion, connectGameService, isLoading } = useAddGameContext();
    const renderedOptions = (connectPlatform === null || connectPlatform === void 0 ? void 0 : connectPlatform.regions)
        ? connectPlatform.regions.map(({ name, id }) => ({
            label: name,
            value: id,
            id,
            paletteName: 'cod',
        }))
        : [];
    const getSelectedRegion = (selectedRegionId) => {
        if (connectPlatform === null || connectPlatform === void 0 ? void 0 : connectPlatform.regions) {
            const selectedRegion = connectPlatform.regions.filter((region) => {
                return region.id === selectedRegionId;
            });
            return selectedRegion.length > 0 ? selectedRegion[0] : null;
        }
        return null;
    };
    const handleSelect = (selectedRegionId) => {
        const selectedRegion = getSelectedRegion(selectedRegionId);
        if (selectedRegion && connectGameService) {
            onRegionSelected(selectedRegion, connectGameService);
        }
    };
    // Revert loading state on unmount.
    // This prevents getting stuck in a loading state if user hits the back button
    // XYG-1619
    useEffect(() => {
        return () => {
            if (isLoading) {
                dispatch(setIsLoadingAction(false));
                dispatch(setCurrentStepAction(currentStep - 1));
            }
        };
    });
    return (_jsxs(ModalContent, { className: className, stepName: stepName, children: [_jsx("h3", { id: "add-game-cod-select-region", children: "Select your account region" }), _jsx(Choice, { choices: renderedOptions, onSelect: handleSelect, initialSelection: isRegion(connectGameRegion) ? connectGameRegion.id : '', defaultPaletteName: "cod", ariaLabelledBy: "add-game-cod-select-region" })] }));
};
export default ContentCODRegions;
