import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import loadable from '@loadable/component';
import { styled } from '@mui/system';
import queryString from 'query-string';
import ModalIconBanner from '@components/Modals/components/ModalIconBanner';
import ModalContent from '@components/Modals/components/ModalContent';
import getOAuthReturnPath from '@helpers/getOAuthReturnPath';
import useTrackEvent from '@hooks/useTrackEvent';
import ConnectGameButton from '../ConnectGameButton';
import { useAddGameContext } from '../context/AddGameProvider';
import { setIsLoadingAction } from '../context/AddGameStepsActions';
import isRegion from '../helpers/isRegion';
const EpicGames = loadable(() => import('@components/UI/Icon/Icons/EpicGames'));
const StyledConnectGameButton = styled(ConnectGameButton) `
  align-self: stretch;
`;
/**
 * @deprecated 2022-10-25 - In favour of AddGameOAuthConnectContent.tsx
 */
const AddGameConnectEpicContent = ({ title, content, className, stepName }) => {
    var _a;
    const { dispatch, isLoading, selectedGame, connectGameRegion, connectGameService } = useAddGameContext();
    const trackEvent = useTrackEvent();
    const loginViaOAuthText = 'Login to Your Account';
    const handleClick = () => {
        dispatch(setIsLoadingAction(true));
        trackEvent({
            type: 'game_linking',
            data: {
                eventName: 'game linking',
                label: loginViaOAuthText,
                moduleName: 'Button',
                position: 0,
            },
        });
    };
    const currentPath = getOAuthReturnPath();
    const params = {
        return_path: currentPath,
        region: connectGameRegion === null || connectGameRegion === void 0 ? void 0 : connectGameRegion.id,
        game: selectedGame === null || selectedGame === void 0 ? void 0 : selectedGame.gameKey,
    };
    const oauthHandover = selectedGame && ((_a = connectGameService === null || connectGameService === void 0 ? void 0 : connectGameService.oauth) === null || _a === void 0 ? void 0 : _a.route) && isRegion(connectGameRegion)
        ? `${connectGameService.oauth.route}?${queryString.stringify(params)}`
        : '';
    return (_jsxs(ModalContent, { className: className, stepName: stepName, align: "center", children: [_jsx(ModalIconBanner, { children: _jsx(EpicGames, { height: 110, color: "grey", strength: "dark" }) }), _jsx("h2", { "data-notranslate": true, children: title || (_jsxs("span", { children: ["Connect to ", _jsx("span", { "data-ignore": true, children: "Epic" })] })) }), _jsx("section", { children: _jsx("p", { children: content || (_jsxs(_Fragment, { children: ["You'll be redirected to the ", _jsx("span", { "data-ignore": true, children: "Epic" }), " Login page in order to complete this process"] })) }) }), _jsx(StyledConnectGameButton, { href: oauthHandover, onClick: handleClick, paletteName: "fortnite", isLoading: isLoading, disabled: isLoading, children: _jsx("span", { children: loginViaOAuthText }) })] }));
};
export default AddGameConnectEpicContent;
