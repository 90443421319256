import triggerLoggedOutRedirect from '@domain/User/Login/helpers/triggerLoggedOutRedirect';
import { isAxiosError } from '@helpers/errorHelper';
import { captureException, withScope } from '@sentry/react';
import { MutationCache, QueryCache, QueryClient } from 'react-query';
export const onRetryHandler = (failureCount, error) => {
    var _a;
    if (!!error && isAxiosError(error) && ((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) && error.response.status < 500) {
        return false;
    }
    // retry 3 times for other errors
    if (failureCount > 2) {
        return false;
    }
    return true;
};
export const onErrorHandler = (error) => {
    var _a;
    if (!!error && isAxiosError(error) && ((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) && error.response.status === 401) {
        triggerLoggedOutRedirect();
    }
};
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: onRetryHandler,
            onError: onErrorHandler,
        },
    },
    mutationCache: new MutationCache({
        onError: onMutationError,
    }),
    queryCache: new QueryCache({
        onError: onQueryError,
    }),
});
export function onMutationError(err, _variables, _context, mutation) {
    withScope((scope) => {
        scope.setContext('mutation', {
            mutationId: mutation.mutationId,
            variables: mutation.state.variables,
        });
        if (mutation.options.mutationKey && typeof mutation.options.mutationKey === 'string') {
            scope.setFingerprint(Array.from(mutation.options.mutationKey));
        }
        captureSentryException(err);
    });
}
export function onQueryError(err, query) {
    withScope((scope) => {
        scope.setContext('query', { queryHash: query.queryHash });
        scope.setFingerprint([query.queryHash.split(/\d/g).join('0')]);
        captureSentryException(err);
    });
}
export function captureSentryException(error) {
    var _a, _b, _c, _d;
    if (isAxiosError(error) && ((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 500) {
        const errorResponse = error;
        captureException(error, {
            extra: {
                responsePayload: (_b = errorResponse === null || errorResponse === void 0 ? void 0 : errorResponse.response) === null || _b === void 0 ? void 0 : _b.data,
                errorCode: (_d = (_c = errorResponse.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.data.errorCode,
            },
        });
    }
}
export default queryClient;
