import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import loadable from '@loadable/component';
import ModalBody from '@components/Modals/components/ModalBody';
import ModalBreadcrumb from '@components/Modals/components/ModalBreadcrumb';
import ModalHeader from '@components/Modals/components/ModalHeader';
import ContentCarousel from '@components/UI/ContentCarousel';
import GameAgeConsent from '@components/Modals/AddGameModal/Components/GameAgeConsent';
import getGamePalette from '@helpers/getGamePalette';
import getGameName from '@helpers/getGameName';
import useAddGameBreadcrumbManager from '@hooks/useAddGameBreadcrumbManager';
import { useState } from 'react';
const GameIcon = loadable(() => import('@components/Icons/GameIcon'));
import useSetDefaultGameService from '../hooks/useSetDefaultGameService';
import ContentPUBGConnect from './ContentPUBGConnect';
import ContentPUBGServices from './ContentPUBGServices';
import AddGameOAuthConnectContent from '../AddGameOAuthConnectContent';
import useHandleAddGameEvents from '../hooks/useHandleAddGameEvents';
import useRemoveAddGameParams from '@hooks/useRemoveAddGameParams';
import AddGameConnectStatus from '../AddGameConnectStatus';
import { useAddGameContext } from '../context/AddGameProvider';
import { setConnectPlatformAction, setConnectRegionAction, setConnectGameServiceAction, } from '../context/AddGameStepsActions';
import getAnalyticsFlowStep from '@domain/Analytics/helpers/getAnalyticsFlowStep';
const gameKey = 'pubg';
const AddPUBG = ({ onStepChange, onConnectConfirm, shouldShowSelectBreadcrumb = true, onBackToSelect, successButtonLabel = 'Continue', failButtonLabel = 'Retry', onClose, }) => {
    const breadcrumbStepOffset = shouldShowSelectBreadcrumb ? 1 : 0;
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const addGameContext = useAddGameContext();
    const removeAddGameParams = useRemoveAddGameParams();
    const { requireAgePrompt, gameConnectionStatus, dispatch, isProviderReady, fetched, connectGameService } = addGameContext;
    const gameStepBreadcrumbs = [
        {
            id: 'service',
            component: 'Platform',
            analyticsFlowStep: 'select game platform',
        },
        {
            id: 'connect-pubg',
            component: 'Connect',
            analyticsFlowStep: (connectGameService === null || connectGameService === void 0 ? void 0 : connectGameService.oauth) ? 'connect game' : 'enter game information',
        },
    ];
    const { breadcrumbIndex, breadcrumbs, breadcrumbIsVisible, setBreadcrumbIsVisible, displayBreadcrumbsUpTo } = useAddGameBreadcrumbManager({
        gameStepBreadcrumbs,
        startingIndex: breadcrumbStepOffset,
        includeSelectBreadcrumb: shouldShowSelectBreadcrumb,
        requireAgePrompt,
    });
    useSetDefaultGameService();
    useHandleAddGameEvents({
        state: addGameContext,
        onAddGameError: () => setCurrentStepIndex(-1),
        onAddGameSuccess: () => setCurrentStepIndex(-1),
    });
    const handleBreadcrumbClick = (idx, breadcrumb) => {
        if (breadcrumb.id === 'select') {
            // Invoke onBackToSelect to dismiss current view
            if (onBackToSelect) {
                onBackToSelect(gameKey);
            }
        }
        else if (idx >= breadcrumbStepOffset) {
            setCurrentStepIndex(idx - breadcrumbStepOffset);
        }
    };
    const handleStepChange = (changedStepIndex, stepName) => {
        if (stepName === 'game-connect-status') {
            setBreadcrumbIsVisible(false);
        }
        else {
            setBreadcrumbIsVisible(true);
            displayBreadcrumbsUpTo(stepName);
        }
        if (onStepChange) {
            const analyticsFlowStep = getAnalyticsFlowStep(gameConnectionStatus, changedStepIndex, gameStepBreadcrumbs);
            onStepChange(changedStepIndex, stepName, analyticsFlowStep, gameStepBreadcrumbs.length);
        }
    };
    const handleContinue = () => {
        setCurrentStepIndex(currentStepIndex + 1);
    };
    const handleConnectSuccessClick = () => {
        if (onConnectConfirm) {
            onConnectConfirm();
        }
    };
    const handleConnectRetryClick = () => {
        removeAddGameParams();
        setCurrentStepIndex(-2); // Second last step
    };
    const handleClose = () => {
        const isSuccessPage = breadcrumbIndex === -1;
        if (onClose) {
            onClose(gameConnectionStatus === 'success', isSuccessPage);
        }
    };
    const updateDefaultPlatformAndRegionFromService = (service) => {
        var _a;
        // Select the platform if there's only one
        if ((service === null || service === void 0 ? void 0 : service.platforms.length) === 1) {
            const selectedPlatform = service.platforms[0];
            dispatch(setConnectPlatformAction(selectedPlatform));
            // If there is only a single region, select it automatically
            if (((_a = selectedPlatform.regions) === null || _a === void 0 ? void 0 : _a.length) === 1) {
                dispatch(setConnectRegionAction(selectedPlatform.regions[0]));
            }
        }
    };
    const handleGameServiceSelect = (gameService) => {
        dispatch(setConnectGameServiceAction(gameService));
        setCurrentStepIndex(currentStepIndex + 1);
        updateDefaultPlatformAndRegionFromService(gameService);
    };
    const handlePUBGAccountSubmit = () => {
        setCurrentStepIndex(currentStepIndex + 1);
    };
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { title: _jsx("span", { "data-ignore": true, children: getGameName(gameKey) }), icon: _jsx(GameIcon, { game: gameKey, showGameColor: true }), subtitle: "Connect your account", paletteName: getGamePalette(gameKey), onClose: handleClose }), _jsx(ModalBreadcrumb, { currentIndex: breadcrumbIndex, breadcrumbs: breadcrumbs, onClick: handleBreadcrumbClick, isVisible: breadcrumbIsVisible }), _jsx(ModalBody, { children: isProviderReady && (_jsxs(ContentCarousel, { name: "PUBG", currentStep: currentStepIndex, onStepChange: handleStepChange, children: [requireAgePrompt && _jsx(GameAgeConsent, { stepName: "age", onContinue: handleContinue }), _jsx(ContentPUBGServices, { onGameServiceSelected: handleGameServiceSelect, stepName: "service" }), (connectGameService === null || connectGameService === void 0 ? void 0 : connectGameService.oauth) && _jsx(AddGameOAuthConnectContent, { stepName: "connect-pubg" }), !(connectGameService === null || connectGameService === void 0 ? void 0 : connectGameService.oauth) && (_jsx(ContentPUBGConnect, { onSubmit: handlePUBGAccountSubmit, stepName: "connect-pubg" })), _jsx(AddGameConnectStatus, { onConfirm: handleConnectSuccessClick, onRetry: handleConnectRetryClick, status: gameConnectionStatus || 'error', gameKey: gameKey, successButtonLabel: successButtonLabel, failButtonLabel: failButtonLabel, errorMessage: (fetched === null || fetched === void 0 ? void 0 : fetched.isError) ? fetched.errorMessage : undefined, stepName: "game-connect-status" })] })) })] }));
};
export default AddPUBG;
