import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import doTrackEvent from '@components/Analytics/doTrackEvent';
import CoreButton from '@components/Buttons/CoreButton';
import getGamePalette from '@helpers/getGamePalette';
import loadable from '@loadable/component';
import { styled } from '@mui/system';
const Icon = loadable(() => import('@components/Icons/Icon'));
const styledOptions = {
    shouldForwardProp: (prop) => prop !== 'palette',
};
const StyledIcon = styled(Icon) `
  fill: ${({ theme }) => theme.palette.grey[400]};
  width: 20px;
`;
const IconWrapper = styled('div', styledOptions) `
  background: ${({ theme, palette }) => (palette ? theme.palette[palette].main : theme.palette.common.white)};
  border-radius: 4px;
  display: flex;
  flex: 0 0 40px;
  height: 40px;
  padding: ${({ theme }) => theme.spacing(1.25)};
  position: relative;
  width: 40px;

  & svg {
    fill: ${({ theme }) => theme.palette.common.white};
    width: 20px;
  }
`;
const PlatformBadge = styled('div', styledOptions) `
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.common.white};
  background: ${({ theme, palette }) => (palette ? theme.palette[palette].main : theme.palette.common.white)};
  border-radius: 9px;
  display: flex;
  height: 18px;
  justify-content: center;
  left: 0;
  margin: 0;
  position: absolute;
  transform: translate(-33%, -33%);
  top: 0;
  width: 18px;

  & svg {
    fill: ${({ theme }) => theme.palette.common.white};
    height: 9px;
    width: 9px;
  }
`;
const TextContent = styled('div') `
  flex: 1 1 auto;
  flex-direction: row;
  max-width: calc(100% - 122px);

  & h3 {
    color: ${({ theme }) => theme.palette.grey[900]};
    font-weight: 700;
    margin: 0;
    white-space: nowrap;
  }

  & p {
    font-weight: 600;
    color: ${({ theme }) => theme.palette.grey[400]};
    margin: 0;
    margin-top: 4px;
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
const StatsButton = styled(CoreButton) `
  color: ${({ theme, color }) => theme.palette[color].main};
  flex: 0 0 50px;
  padding: ${({ theme }) => theme.spacing(1)}!important;

  &:hover {
    background-color: ${({ theme, color }) => theme.palette[color].main};
    color: ${({ theme }) => theme.palette.common.white} !important;
  }
`;
const Container = styled(`div`) `
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 4px;
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  min-height: 70px;
  padding: ${({ theme }) => theme.spacing(2)};
`;
const PublicGameAccount = ({ userGameRegion }) => {
    const { handle, region, platform, game: { gameKey, name: gameName }, statsLink, } = userGameRegion;
    const abbreviation = platform === null || platform === void 0 ? void 0 : platform.abbreviation;
    const platformName = platform === null || platform === void 0 ? void 0 : platform.name;
    const gamePalette = getGamePalette(gameKey);
    const trackStatsButtonClick = () => {
        doTrackEvent({
            type: 'open_external_stats',
            link: statsLink !== null && statsLink !== void 0 ? statsLink : '',
            account: handle,
            game: gameKey,
        });
    };
    const shouldShowStatsButton = statsLink && statsLink !== '';
    return (_jsxs(Container, { children: [_jsxs(IconWrapper, { palette: gamePalette, children: [abbreviation && (_jsx(PlatformBadge, { palette: gamePalette, children: _jsx(Icon, { title: platformName, icon: abbreviation }) })), _jsx(StyledIcon, { icon: gameKey, title: gameName })] }), _jsxs(TextContent, { children: [_jsx("h3", { "data-notranslate": true, children: handle }), _jsx("p", { children: platformName ? `${region} | ${platformName}` : region })] }), shouldShowStatsButton && (_jsx(StatsButton, { onClick: trackStatsButtonClick, to: statsLink, size: "sm", variant: gamePalette, appearance: "outline", color: gamePalette, children: "Stats" }))] }));
};
export default PublicGameAccount;
