import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import Choice from '@components/UI/Choice/Choice';
import CrossCloseIcon from '@components/UI/CrossCloseIcon';
import GenericModal from '@components/Modals/GenericModal';
import ModalContent from '@components/Modals/components/ModalContent';
import ModalBody from '@components/Modals/components/ModalBody';
import { useUserState } from '@context/UserContext';
import BuyCoins from '@components/Modals/AddFunds/components/BuyCoins/BuyCoins';
import DepositCash from '@components/Modals/AddFunds/components/DepositCash/DepositCash';
const useStyles = makeStyles()(() => ({
    closeBtn: {
        background: 'transparent',
        border: 'none',
        height: '30px',
        position: 'absolute',
        right: '10px',
        top: '10px',
        width: '30px',
    },
    headerCloseBtn: {
        color: 'white',
    },
    image: {
        height: '20px',
    },
}));
/**
 * Modal for depositing cash or coins
 *
 * @param selectedCurrency - determines whether the modal displays with the specified currency preselected.
 */
const AddFunds = ({ onClose, selectedCurrency }) => {
    const { classes } = useStyles();
    const { userDetails } = useUserState();
    const [currency, setCurrency] = useState(selectedCurrency);
    const preSelectedCurrency = selectedCurrency !== undefined;
    const choices = [
        {
            id: 'coin',
            label: 'Buy Coins',
            value: 'PM',
            icon: _jsx("img", { src: "/img/icons/coins.svg", alt: "coins", className: classes.image }),
            paletteName: 'secondary',
        },
    ];
    if (userDetails && userDetails.age >= 18) {
        choices.push({
            id: 'cash',
            label: 'Deposit Cash',
            value: 'USD',
            icon: _jsx("img", { src: "/img/icons/dollar.svg", alt: "dollar", className: classes.image }),
            paletteName: 'primary',
        });
    }
    const handleCurrencySelect = (selected) => {
        if (selected === 'PM' || selected === 'USD') {
            setCurrency(selected);
        }
    };
    const handleModalClose = () => {
        setCurrency(undefined);
    };
    return (_jsxs(GenericModal, { show: true, size: "small", children: [!currency && (_jsxs(_Fragment, { children: [_jsx(CrossCloseIcon, { action: onClose, className: classes.closeBtn }), _jsx(ModalBody, { children: _jsxs(ModalContent, { align: "center", children: [_jsx("h2", { children: "Your Wallet" }), _jsx("span", { children: "Please select one of the actions below in order to continue with the process" }), _jsx(Choice, { choices: choices, onSelect: handleCurrencySelect })] }) })] })), currency === 'PM' && (_jsx(BuyCoins, { onClose: onClose, onResetModal: handleModalClose, preSelectedCurrency: preSelectedCurrency })), currency === 'USD' && (_jsx(DepositCash, { onClose: onClose, onResetModal: handleModalClose, preSelectedCurrency: preSelectedCurrency }))] }));
};
export default AddFunds;
