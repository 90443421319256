import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from '@mui/material/styles';
const StyledErrorWrapper = styled('div')({
    '& .repeat404Wrapper, & .repeat401Wrapper': {
        fontFamily: 'Montserrat',
        padding: '120px 20px 70px',
        display: 'flex',
        justifyContent: 'center',
        '& .repeat404Content, & .repeat401Content': {
            maxWidth: '400px',
            textAlign: 'center',
        },
        '& img': {
            maxWidth: '175px',
        },
        '& h1': {
            fontWeight: 800,
            margin: '36px 0',
            fontSize: '24px',
            color: '#2e2e2e',
        },
        '& .buttonContainer': {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '36px',
            '& .btn': {
                fontWeight: 'bold',
                lineHeight: '48px',
                padding: '0 36px',
                '& + .btn': {
                    marginLeft: '15px',
                },
            },
        },
    },
});
const ErrorWrapper = ({ children }) => {
    return (_jsx(StyledErrorWrapper, { "data-testid": "404 page", children: children }));
};
export default ErrorWrapper;
