import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import loadable from '@loadable/component';
import { css, styled } from '@mui/system';
import PlatformIcon from '@components/UI/Icon/PlatformIcon';
import getTileHeroImageByGameKey from '@helpers/getTileHeroImageByGameKey';
import getGameBackground from '@helpers/getGameBackground';
import GameWordmark from '@components/UI/GameWordmark/GameWordmark';
import useGamesList from '../../Hooks/API/useGamesList';
import PlatformSelectModal from '@domain/Header/components/Menus/PlatformSelectModal';
import { useMemo, useState } from 'react';
import useLocalStorage from '@components/Hooks/useLocalStorage';
import UnstyledLinkButton from '@components/Buttons/UnstyledLinkButton';
import getGamePalette from '@helpers/getGamePalette';
import IconBackground from '@components/UI/Icon/IconBackground';
import SROnly from '@components/UI/SROnly';
import { useTheme, useMediaQuery } from '@mui/material';
const BetaLabel = loadable(() => import('@components/Icons/BetaLabel'));
const shouldForwardProp = (prop) => prop !== 'hasPlatformSelected' && prop !== 'beforeContent' && prop !== 'showMobileMenu';
const BackgroundFade = styled('div') `
  transition: opacity 0.5s linear;
  position: absolute;
  border-radius: 6px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-color: ${({ theme, color }) => (color === 'madden-25' ? '#000000' : theme.palette[color].main)};
  z-index: 1;
`;
const BaseTile = styled(UnstyledLinkButton) `
  display: inline-flex;
  width: 100%;
  min-width: ${({ size }) => (size === 'md' ? '244px' : '157px')};
  height: ${({ size }) => (size === 'md' ? '296px' : '153px')};
  border-radius: 6px;
  border: none;
  padding-top: ${({ size }) => (size === 'md' ? '20px' : '16px')};
  position: relative;
  justify-content: center;
  background-image: url(${({ game }) => getGameBackground(game)});
  background-size: cover;
  background-position: top;
  img {
    transition:
      transform 0.5s,
      filter 0.5s;
    aspect-ratio: 1;
  }
`;
const StaticFade = styled('div') `
  background: linear-gradient(
    180deg,
    rgba(2, 0, 36, 0) 0%,
    ${({ theme, color }) => (color === 'madden-25' ? '#000000' : theme.palette[color].main)} 50%
  );
  z-index: 3;
  width: 100%;
  height: 40%;
  position: absolute;
  bottom: 0;
  border-radius: 6px;
`;
const Shimmer = styled('div') `
  z-index: 4;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  border-radius: 6px;
  opacity: 0;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0) 40%, rgba(255, 255, 255, 0.5) 50%, rgba(0, 0, 0, 0) 60%);
  background-size: 200% 200%;
  animation: Reset 0.2s linear;
  background-position: 0 100%;
  @keyframes Reset {
    0% {
      background-position: 100% 0;
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      background-position: 0 100%;
      opacity: 0;
    }
  }
  &:hover {
    animation: Shimmer 0.2s linear;
    background-position: 100% 0;
    opacity: 0;
    @keyframes Shimmer {
      0% {
        background-position: 0 100%;
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        background-position: 100% 0;
        opacity: 0;
      }
    }
  }
`;
const GameLogo = styled(GameWordmark) `
  z-index: 4;
  fill: #ffffff;
`;
const HeroImage = styled('img') `
  padding-bottom: ${({ theme }) => theme.spacing(1.5)};
  width: auto !important;
  height: ${({ size }) => (size === 'md' ? '220px' : '110px')};
  position: absolute;
  bottom: 0;
  z-index: 2;
  aspect-ratio: auto !important;
`;
const ActionButton = styled('div') `
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${({ size }) => (size === 'md' ? '55px' : '36px')};
  border: none;
  background-color: ${({ theme, color }) => (color === 'madden-25' ? '#202020' : theme.palette[color].dark)};
  color: ${({ theme }) => theme.palette.common.white};
  border-radius: 0 0 6px 6px;
  font-weight: 600;
  z-index: 5;
  text-align: center;
`;
const ActionText = styled('span', { shouldForwardProp }) `
  transition: all 0.5s ease-in-out;
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  &::before,
  &:after {
    transition: opacity 0.2s linear;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  &:before {
    opacity: 1;
    content: '${(props) => props.beforeContent}';
  }

  &:after {
    opacity: 0;
    content: 'Choose Platform';
    filter: drop-shadow(2px 2px 2px #00000087);
  }
`;
const PlatformSelectContainer = styled('section') `
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border: none;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 0 0 5px 5px;
  font-weight: 600;
  z-index: 7;
  opacity: 0;
  transition: opacity 0.5s linear;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0;
  padding: 10px 0;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const BetaBanner = styled(BetaLabel) `
  position: absolute;
  z-index: 4;
  top: 0;
  right: 0;
`;
const Container = styled('div', { shouldForwardProp }) `
  position: relative;
  display: inline-block;
  width: 100%;
  border-radius: 6px;
  transition: box-shadow 0.3s linear;
  &:hover {
    box-shadow: 0 10px 22px 0 rgba(0, 0, 0, 0.2);
    & > section {
      opacity: 1;
    }

    & span {
      ${({ showMobileMenu, hasPlatformSelected }) => !showMobileMenu &&
    !hasPlatformSelected &&
    css `
          transform: translate(0px, -50px);
        `}
      ${(props) => !props.hasPlatformSelected &&
    css `
          &:before {
            opacity: 0;
          }
          &:after {
            opacity: 1;
          }
        `}
    }

    &:hover {
      img {
        filter: drop-shadow(2px 4px 8px rgba(255, 255, 255, 0.4));
        transform: scale(1.05);
      }

      & .background-fade {
        opacity: 0.7;
      }
    }
  }

  & > button {
    cursor: initial;
  }
`;
const PlatformLinkButton = styled(UnstyledLinkButton) `
  &:disabled {
    background: none;
    border: none;
    padding: unset;
    cursor: initial;
  }
`;
const platformSortList = {
    playstation: 0,
    pc: 1,
    mobile: 2,
    xbox: 3,
    switch: 4,
};
/**
 * @param { Props } props
 * @param { 'md' |' sm' } props.size either medium 'md' for desktop display or small 'sm' for mobile displays
 * @param { SimplifiedGame } props.game SimplifiedGame object
 * @param { PlatformKey } props.platform define this if you want the user to go to /${platform}/${gameRoute}, leave undefined if you want the user to go to the game hub
 */
const GameTile = ({ size, game, platform }) => {
    const { gameKey, slug: gameRoute, platforms, status } = game;
    const { allPlatforms, isLoading } = useGamesList();
    const platformArray = platforms && platforms.map((platform) => platform.abbreviation);
    const [modalOpen, setModalOpen] = useState(false);
    const [_, setSelectedPlatformSlug] = useLocalStorage('selectedPlatformSlug', '');
    const theme = useTheme();
    const showMobileMenu = useMediaQuery(theme.breakpoints.down('header'));
    const sortedPlatforms = useMemo(() => {
        return allPlatforms.sort((a, b) => {
            return platformSortList[a.slug] - platformSortList[b.slug];
        });
    }, [allPlatforms]);
    const getActionButtonText = (status) => {
        switch (status) {
            case 'paused':
            case 'inactive':
                return 'Paused';
            case 'prelaunch':
                return 'Pre-Register';
            case 'coming':
                return 'Coming Soon';
            case 'active':
            case 'beta':
                return 'Play Now';
        }
    };
    let to;
    if (platform) {
        to = `/${platform}/${gameRoute}`;
    }
    else if (!platformArray) {
        to = `/${gameRoute}`;
    }
    const onClick = () => {
        if (!modalOpen && platformArray && showMobileMenu) {
            setModalOpen(true);
        }
    };
    return (_jsxs(Container, { showMobileMenu: showMobileMenu, hasPlatformSelected: platform !== undefined, children: [_jsxs(BaseTile, { to: to, onClick: onClick, size: size, game: gameKey, platforms: platformArray, children: [_jsx(BackgroundFade, { className: "background-fade", color: getGamePalette(gameKey) }), _jsx(StaticFade, { color: getGamePalette(gameKey) }), _jsx(Shimmer, {}), _jsx(GameLogo, { gameKey: gameKey, height: size === 'md' ? 40 : 26, width: size === 'md' ? 170 : 110 }), _jsx(HeroImage, { size: size, src: getTileHeroImageByGameKey(gameKey), alt: game.name }), _jsxs(ActionButton, { size: size, color: getGamePalette(gameKey), children: [_jsx(SROnly, { children: getActionButtonText(status) }), _jsx(ActionText, { beforeContent: getActionButtonText(status) })] }), status === 'beta' && _jsx(BetaBanner, {})] }), _jsx(PlatformSelectModal, { isOpen: modalOpen, onClose: () => {
                    setModalOpen(false);
                }, gameKey: gameKey }), platformArray && !platform && !showMobileMenu && (_jsx(PlatformSelectContainer, { children: !isLoading &&
                    sortedPlatforms.map((platform) => {
                        const isDisabled = !platformArray.includes(platform.abbreviation);
                        return (_jsx(PlatformLinkButton, { disabled: isDisabled, onClick: () => {
                                setSelectedPlatformSlug(platform.slug);
                            }, to: !isDisabled ? `/${platform.slug}/${gameRoute}` : undefined, children: _jsx(IconBackground, { size: "md", isDisabled: isDisabled, platformSlug: platform.slug, children: _jsx(PlatformIcon, { width: 20, platform: platform.slug, title: platform === null || platform === void 0 ? void 0 : platform.abbreviation }) }) }, `${gameKey}-${platform.abbreviation}`));
                    }) }))] }));
};
export default GameTile;
