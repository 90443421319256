import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import CoreButton from '@components/Buttons/CoreButton';
import useGetBalance from '@components/Hooks/useGetBalance';
import CaretDownRotate from '@components/UI/CaretDownRotate';
import CrossCloseIcon from '@components/UI/CrossCloseIcon';
import { useUserState } from '@context/UserContext';
import loadable from '@loadable/component';
import { Collapse, Divider, Drawer, List } from '@mui/material';
import { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import Avatar from '@components/UI/Avatar/Avatar';
import MobileProfileBalances from '../MobileProfileBalances';
import LanguageMenu from './LanguageMenu';
import NavigationMenuMobile from './NavigationMenuMobile';
import ProfileMenu from './ProfileMenu';
const Wallet = loadable(() => import('@components/Icons/Wallet'));
const useStyles = makeStyles()((theme) => ({
    drawerPaper: {
        width: '100%',
        top: '72px',
        height: 'calc(100% - 72px)',
    },
    drawerProps: {
        height: '100%',
        // Disabled because this is currently the best way around resolving the z-index issues
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        zIndex: '999999 !important',
        fontFamily: theme.typography.fontFamily,
    },
    menuHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        minHeight: '54px',
        borderBottom: `1px solid ${theme.palette.grey[100]}`,
        '& > div': {
            display: 'flex',
            alignItems: 'stretch',
        },
        '& button': {
            borderRadius: '0px',
        },
    },
    walletIcon: {
        fill: theme.palette.common.white,
        width: '18px',
        marginRight: theme.spacing(1),
    },
    crossCloseIcon: {
        borderLeft: `1px solid ${theme.palette.grey[100]}`,
        height: 'initial',
    },
    languageMenuStyle: {
        display: 'flex',
        justifyContent: 'center',
    },
    menu: {
        display: 'flex',
        alignItems: 'center',
        padding: '0',
        marginLeft: '0px',
        flexDirection: 'column',
    },
    divider: {
        width: '100%',
    },
    newDrawerPaper: {
        top: 0,
        height: '100%',
        // Review this when MobileNavigationMenu fully migrates over to new design.
        fontSize: '12px',
        color: theme.palette.grey[600],
        '& a, & div[role="button"]': {
            height: '50px', // Override old design min-height when feature flag is on.
        },
        '& a span': {
            fontWeight: 600,
        },
    },
    userProfileMenu: {
        '& a, & button, & div[role="button"]': {
            padding: theme.spacing(0, 2),
        },
    },
    userProfileMenuButton: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: theme.palette.grey[50],
        fontSize: '14px',
        fontWeight: 600,
        width: '100%',
        padding: theme.spacing(2),
        border: '0',
        borderBottom: `1px solid ${theme.palette.grey[100]}`,
        '& > div': {
            display: 'flex',
            alignItems: 'center',
        },
        '& p': {
            color: theme.palette.grey[900],
            margin: 0,
        },
        '& img': {
            width: '35px',
            height: '35px',
            borderRadius: theme.shape.borderRadius,
            marginRight: theme.spacing(1.5),
        },
    },
    collapse: {
        width: '100%',
    },
}));
/*

A generic drawer component
This one has been styled for mobile usage so that the header section still displays above the drawer

*/
const MobileNavigationMenu = ({ open, openProfileMenu, onClose, loginAction, onShowFundsModal, onShowCouponModal, }) => {
    var _a, _b, _c;
    const { classes, cx } = useStyles();
    const { userProfile, authenticated } = useUserState();
    const balances = useGetBalance();
    const [showUserProfileMenu, setShowUserProfileMenu] = useState(openProfileMenu);
    // eslint-disable-next-line @kyleshevlin/prefer-custom-hooks
    useEffect(() => {
        setShowUserProfileMenu(openProfileMenu);
    }, [openProfileMenu]);
    return (_jsxs(Drawer, { "data-testid": "header drawer", open: open, anchor: "right", classes: {
            paper: cx(classes.drawerPaper, classes.newDrawerPaper),
            root: classes.drawerProps,
        }, onClose: onClose, ModalProps: {
            // This prevents maximum callstack errors when bootstrap modal display above the drawer
            disableEnforceFocus: true,
        }, children: [_jsxs("div", { className: classes.menuHeader, children: [_jsxs("div", { children: [authenticated ? (_jsxs(CoreButton, { onClick: onShowFundsModal, children: [_jsx(Wallet, { className: classes.walletIcon }), " Add Funds"] })) : (_jsx(CoreButton, { size: "lg", onClick: loginAction, children: "Log In" })), _jsx(LanguageMenu, { className: classes.languageMenuStyle })] }), _jsx(CrossCloseIcon, { action: onClose, className: classes.crossCloseIcon })] }), _jsxs(List, { component: "nav", "aria-label": "navigation menu", classes: { root: classes.menu }, children: [authenticated && userProfile && (_jsxs(_Fragment, { children: [_jsxs("button", { type: "button", onClick: () => setShowUserProfileMenu(!showUserProfileMenu), className: classes.userProfileMenuButton, children: [_jsxs("div", { children: [_jsx(Avatar, { handle: (_a = userProfile === null || userProfile === void 0 ? void 0 : userProfile.handle) !== null && _a !== void 0 ? _a : '', borderUrl: (_b = userProfile === null || userProfile === void 0 ? void 0 : userProfile.borderUrl) !== null && _b !== void 0 ? _b : '', avatarUrl: (_c = userProfile === null || userProfile === void 0 ? void 0 : userProfile.profileTinyUrl) !== null && _c !== void 0 ? _c : '' }), _jsx("p", { "data-notranslate": true, children: userProfile.handle })] }), _jsx(CaretDownRotate, { rotate: showUserProfileMenu })] }), _jsx(Collapse, { in: showUserProfileMenu, timeout: "auto", unmountOnExit: true, classes: {
                                    root: classes.collapse,
                                }, children: _jsx("div", { className: classes.userProfileMenu, children: _jsx(ProfileMenu, { showCouponModal: onShowCouponModal }) }) }), _jsx(MobileProfileBalances, { onShowFundsModal: onShowFundsModal, balances: balances, onClose: onClose }), _jsx(Divider, { className: classes.divider })] })), _jsx(NavigationMenuMobile, { onClose: onClose })] })] }));
};
export default MobileNavigationMenu;
