import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import Dot from './Dot';
const Dots = ({ onChange, value, slideCount, slidesPerPage }) => {
    const dotCount = Math.ceil(slideCount / slidesPerPage);
    const increment = slideCount / dotCount;
    const dotsArray = Array.from({ length: dotCount }, (_, idx) => ({
        value: idx * increment,
        content: idx + 1,
        min: idx * increment,
        max: idx * increment + increment,
    }));
    if (slideCount <= slidesPerPage) {
        return _jsx(_Fragment, {});
    }
    return (_jsx(_Fragment, { children: dotsArray.map((dot, idx) => (_jsx(Dot, { onClick: () => onChange(idx), active: value === idx, children: dot.content }, dot.value))) }));
};
export default Dots;
