import { captureException } from '@sentry/react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import doTrackEvent from '@components/Analytics/doTrackEvent';
import isFirebaseAuthError from './isFirebaseAuthError';
/**
 * Prompt the user to authenticate with Facebook
 *
 * @param props The component props
 * @param props.authenticationAction Indicates if this is a login or sign up attempt
 * @param props.scopes The scopes to request from the user
 * @returns Authenticated firebase user
 * @throws unknown
 */
const authenticateWithFacebook = async ({ authenticationAction, scopes, }) => {
    var _a;
    try {
        const auth = firebase.auth();
        const facebookProvider = new firebase.auth.FacebookAuthProvider();
        if (scopes) {
            scopes.forEach((scope) => facebookProvider.addScope(scope));
        }
        const signInResult = await auth.signInWithPopup(facebookProvider);
        doTrackEvent({
            type: 'authenticate_with_provider',
            authenticationAction,
            provider: 'facebook',
            userId: (_a = signInResult === null || signInResult === void 0 ? void 0 : signInResult.user) === null || _a === void 0 ? void 0 : _a.uid,
        });
        return signInResult;
    }
    catch (error) {
        if (isFirebaseAuthError(error)) {
            // Only capture unexpected errors
            if (error.code !== 'auth/popup-closed-by-user' && error.code !== 'auth/cancelled-popup-request') {
                captureException(error);
            }
        }
        throw error;
    }
};
export default authenticateWithFacebook;
