import { jsx as _jsx } from "react/jsx-runtime";
import doTrackEvent from '@components/Analytics/doTrackEvent';
import useGamePageUrl from '@hooks/useGamePageUrl';
import useSelectedPlatform from '@hooks/useSelectedPlatform';
import { matchPath, NavLink } from 'react-router-dom';
/**
 * Renders a react-router NavLink that points to a game page
 */
const GameNavLink = ({ children, className, activeClassName, page, game, region, onClick }) => {
    const gamePageUrl = useGamePageUrl(page, game);
    const { platformSlug } = useSelectedPlatform();
    const handleClickEvent = () => {
        doTrackEvent({
            type: 'interact_navigation',
            interaction: 'click',
            region,
            location: game === null || game === void 0 ? void 0 : game.name,
            value: page,
        });
        onClick === null || onClick === void 0 ? void 0 : onClick();
    };
    return (_jsx(NavLink, { exact: true, to: gamePageUrl, className: className, activeClassName: activeClassName, onClick: handleClickEvent, isActive: (_match, location) => !!matchPath(location.pathname, {
            path: [
                `/${game === null || game === void 0 ? void 0 : game.slug}${page === 'Tournaments' ? '' : '/game-hub'}`,
                `/${platformSlug}/${game === null || game === void 0 ? void 0 : game.slug}${page === 'Tournaments' ? '' : '/game-hub'}`,
            ],
            exact: true,
        }), children: children }));
};
export default GameNavLink;
