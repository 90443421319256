import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import TextInput from "@components/Fields/TextInput";
import useFirebaseAuthRequest from '@components/Modals/AuthenticationModal/Hooks/useFirebaseAuthRequest';
import useGetRecaptureEnabled from '@components/Modals/AuthenticationModal/Hooks/useGetRecaptureEnabled';
import AuthenticationSupport from '@components/Modals/AuthenticationModal/Components/Login/components/AuthenticationSupport';
import validateLogin from './helpers/validateLogin';
import trackHotjarEvent from '@components/Analytics/trackHotjarEvent';
import BoxButton from '@components/Buttons/BoxButton';
import TextFieldWrapper from '@components/UI/TextFieldWrapper';
const useStyles = makeStyles()((theme) => ({
    removePadding: {
        padding: '0',
    },
    loginFormContainer: {
        fontFamily: theme.typography.fontFamily,
        background: '#fff',
    },
    loginFormError: {
        color: theme.palette.error.main,
        fontSize: '11px',
        textAlign: 'center',
        fontWeight: 600,
    },
    loginButton: {
        width: '100%',
        height: '50px',
    },
    noAccountFooter: {
        fontSize: '11px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        fontWeight: 600,
        color: theme.palette.grey[400],
        paddingTop: theme.spacing(3),
    },
    createAccountLink: {
        color: theme.palette.primary.main,
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));
const LoginForm = ({ successAction, closeAction }) => {
    const { classes, cx } = useStyles();
    const [form, setForm] = useState({ email: '', password: '' });
    const [validation, setValidation] = useState({ email: '', password: '' });
    const [serverError, setServerError] = useState(null);
    const { data } = useGetRecaptureEnabled();
    const onFirebaseAuthSuccess = () => {
        successAction();
        trackHotjarEvent('Sign in success');
    };
    const onFirebaseAuthError = (errorMessage) => {
        setServerError(errorMessage !== null && errorMessage !== void 0 ? errorMessage : null);
        trackHotjarEvent('Sign in fail');
    };
    const [firebaseAuthState, requestFirebaseAuth] = useFirebaseAuthRequest({
        onSuccess: onFirebaseAuthSuccess,
        onFail: onFirebaseAuthError,
    });
    const handleBlur = (e) => {
        const fieldName = e.currentTarget.name;
        const loginValidation = validateLogin(form);
        loginValidation.passes(); // triggers the error msgs to become available
        const validationError = loginValidation.errors.first(fieldName) || ''; // set or reset error.
        setValidation({ ...validation, [fieldName]: validationError });
    };
    const handleChange = (e) => {
        const fieldName = e.currentTarget.name;
        const fieldValue = e.currentTarget.value;
        setForm({
            ...form,
            [fieldName]: fieldValue,
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const loginValidation = validateLogin(form);
        // csrfToken should be validated
        if (loginValidation.passes()) {
            requestFirebaseAuth(form);
        }
        else {
            // something here regarding no csrfToken?
            const emailValidationError = loginValidation.errors.first('email') || '';
            const passwordValidationError = loginValidation.errors.first('password') || '';
            setValidation({ email: emailValidationError, password: passwordValidationError });
        }
    };
    return (_jsxs("div", { className: cx('repeat-login', classes.loginFormContainer), children: [serverError && (_jsx("p", { role: "alert", className: classes.loginFormError, "data-testid": "formError", children: serverError })), _jsxs("form", { onSubmit: handleSubmit, className: classes.removePadding, children: [_jsxs(TextFieldWrapper, { children: [_jsx(TextInput, { type: "email", id: "email", onChange: handleChange, onBlur: handleBlur, value: form.email, placeholder: "Your Email Address...", label: "Email Address", error: !!validation.email, errorMessage: validation.email, autoFocus: true }), _jsx(TextInput, { type: "password", id: "password", onChange: handleChange, onBlur: handleBlur, value: form.password, label: "Your Password", placeholder: "Enter Your Password...", error: !!validation.password, errorMessage: validation.password })] }), _jsx(AuthenticationSupport, { closeAction: closeAction }), (data === null || data === void 0 ? void 0 : data.recaptchaEnabled) && (_jsx("div", { className: "g-recaptcha", "data-sitekey": "6Ldk1cIUAAAAACQiYDy5zcbF33p3e4Iahsmz0FE9" })), _jsx(BoxButton, { className: cx('input-group', 'submit-btn', classes.loginButton), type: "submit", loading: firebaseAuthState.loading || firebaseAuthState.isSuccess, disabled: firebaseAuthState.loading || firebaseAuthState.isSuccess, ariaLabel: "login", children: "Login" })] })] }));
};
export default LoginForm;
