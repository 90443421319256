import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import CrossCloseIcon from '@components/UI/CrossCloseIcon';
import GenericModal from './GenericModal';
import CoreButton from '@components/Buttons/CoreButton';
import SyncIcon from '@components/Icons/Sync';
const useStyles = makeStyles()((theme, { confirmColor }) => ({
    container: {
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
        fontFamily: theme.typography.fontFamily,
        fontSize: '12px',
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3),
        },
    },
    banner: {
        paddingTop: theme.spacing(4),
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
        '&>h2': {
            fontSize: '26px',
            fontWeight: 700,
            marginTop: theme.spacing(5),
            color: theme.palette.grey[900],
        },
        '&>p': {
            margin: '24px',
            marginBottom: '38px',
            color: '#73787D',
        },
    },
    buttons: {
        display: 'grid',
        width: '100%',
        gap: theme.spacing(2),
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr',
        },
    },
    animateSpin: {
        WebkitAnimation: 'animate-spin 2s infinite linear',
        animation: 'animate-spin 2s infinite linear',
    },
    '@keyframes animate-spin': {
        '0%': {
            transform: 'rotate(0deg)',
        },
        '100%': {
            transform: 'rotate(360deg)',
        },
    },
    confirmBtn: {
        backgroundColor: theme.palette[confirmColor].main,
        border: theme.palette[confirmColor].main,
        '&:hover': {
            backgroundColor: theme.palette[confirmColor].dark,
            border: theme.palette[confirmColor].dark,
        },
    },
    cancelButton: {
        color: theme.palette.grey[300],
        border: `2px solid ${theme.palette.grey[100]}`,
        fontWeight: 'bold',
        '&:hover': {
            color: theme.palette[confirmColor].main,
        },
    },
    closeBtn: {
        position: 'absolute',
        top: theme.spacing(0.5),
        right: theme.spacing(0.5),
        fontSize: '26px',
    },
}));
const ConfirmModal = ({ show, icon, heading, description, className, loading = false, confirmText = 'Confirm', confirmColor = 'primary', cancelText = 'Cancel', handleConfirm, handleClose, children, size = 'small', }) => {
    const { classes, cx } = useStyles({ confirmColor });
    return (_jsx(GenericModal, { className: cx(className), size: size, show: show, ariaLabelledBy: "confirm-modal-heading", children: show && (_jsxs("div", { className: classes.container, children: [_jsx(CrossCloseIcon, { className: classes.closeBtn, action: handleClose }), _jsxs("div", { className: classes.content, children: [!!icon && _jsx("div", { className: classes.banner, children: icon }), _jsx("h2", { id: "confirm-modal-heading", children: heading }), _jsx("p", { children: description }), children, _jsxs("div", { className: classes.buttons, children: [_jsx(CoreButton, { size: "md", block: true, onClick: handleConfirm, disabled: loading, "aria-label": confirmText, loading: loading, "data-testid": "confirm modal button", className: classes.confirmBtn, children: loading ? _jsx(SyncIcon, { className: classes.animateSpin }) : _jsx(_Fragment, { children: confirmText }) }), _jsx(CoreButton, { size: "md", appearance: "outline", className: classes.cancelButton, disabled: loading, block: true, onClick: handleClose, children: cancelText })] })] })] })) }));
};
export default ConfirmModal;
