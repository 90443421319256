import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from '@mui/material';
import useEmblaCarousel from 'embla-carousel-react';
import { useEffect } from 'react';
const CarouselContentContainer = styled('div') `
  overflow: hidden;
`;
const CarouselContentItemsWrapper = styled('div') `
  display: flex;
`;
const CarouselContent = ({ children, opts, plugins, setEmblaApi, setCarouselIndex, className, }) => {
    const [ref, api] = useEmblaCarousel(opts, plugins);
    useEffect(() => {
        if (!api || !setEmblaApi) {
            return;
        }
        setEmblaApi(api);
    }, [api, setEmblaApi]);
    useEffect(() => {
        if (api && setCarouselIndex) {
            // sets carousel index when dragging slides manually
            api.on('select', (emblaApi) => setCarouselIndex(emblaApi.selectedScrollSnap()));
        }
    }, [api, setCarouselIndex]);
    return (_jsx(CarouselContentContainer, { ref: ref, className: className, children: _jsx(CarouselContentItemsWrapper, { children: children }) }));
};
export default CarouselContent;
