import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from '@mui/material';
const CarouselSlideContainer = styled('div') `
  flex: 0 0 100%;
  min-width: 0;
`;
const CarouselSlide = ({ children, className }) => {
    return (_jsx(CarouselSlideContainer, { role: "group", "aria-roledescription": "slide", className: className, children: children }));
};
export default CarouselSlide;
