import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/button-has-type */
import * as React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import Spinner from '@components/Icons/Spinner';
const getVariantDarkColor = (variant, theme) => {
    switch (variant) {
        case 'white':
            return theme.palette.common.white;
        case 'grey':
            return theme.palette.grey[600];
        // Since the deprecated Button lacked a dark variant,
        // we use the main variant for the hover color to ensure a seamless migration without side effects.
        case 'primary':
            return theme.palette.primary.main;
        default:
            return theme.palette[variant].dark;
    }
};
const getVariantColor = (variant, theme) => {
    switch (variant) {
        case 'white':
            return theme.palette.common.white;
        case 'grey':
            return theme.palette.grey[600];
        default:
            return theme.palette[variant].main;
    }
};
const getVariantLight = (variant, theme) => {
    switch (variant) {
        case 'white':
            return theme.palette.common.white;
        case 'grey':
            return theme.palette.grey[100];
        default:
            return theme.palette[variant].light;
    }
};
const getVariantContrast = (variant, theme) => {
    var _a, _b;
    switch (variant) {
        case 'primary':
        case 'warning':
        case 'dota2':
            return theme.palette.common.white;
        case 'white':
            return theme.palette.grey[900];
        case 'grey':
            return theme.palette.common.white;
        default:
            return (((_b = (_a = theme.palette) === null || _a === void 0 ? void 0 : _a[variant]) === null || _b === void 0 ? void 0 : _b.contrastText) ||
                theme.palette.getContrastText(getVariantColor(variant, theme)));
    }
};
const useStyles = makeStyles()((theme, { variant, fancyHoverColor, addFancyHover }) => {
    var _a, _b, _c;
    const variantContrast = getVariantContrast(variant, theme);
    const variantColor = getVariantColor(variant, theme);
    const variantDarkColor = getVariantDarkColor(variant, theme);
    const variantLightColor = getVariantLight(variant, theme);
    const hoverColor = fancyHoverColor ? ((_c = (_b = (_a = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _a === void 0 ? void 0 : _a[fancyHoverColor]) === null || _b === void 0 ? void 0 : _b.main) !== null && _c !== void 0 ? _c : 'white') : 'white';
    return {
        core: {
            alignItems: 'center',
            display: 'inline-flex',
            fontFamily: theme.typography.fontFamily,
            justifyContent: 'center',
            '&:hover, &:active, &:focus': {
                ...(addFancyHover
                    ? {
                        color: hoverColor,
                    }
                    : {
                        color: variantContrast,
                        backgroundColor: variantDarkColor,
                    }),
            },
        },
        sm: {
            fontSize: '12px',
            fontWeight: theme.typography.fontWeightMedium,
            padding: theme.spacing(1, 2),
        },
        md: {
            fontSize: '12px',
            fontWeight: theme.typography.fontWeightMedium,
            padding: theme.spacing(1.5, 3),
        },
        lg: {
            fontSize: '14px',
            fontWeight: theme.typography.fontWeightMedium,
            padding: theme.spacing(2, 5),
        },
        block: {
            width: '100%',
        },
        loading: {
            fontSize: 15,
        },
        disabled: {
            pointerEvents: 'none',
            opacity: 0.5,
        },
        fancyHover: {
            overflow: 'hidden',
            position: 'relative',
            transition: `all 0.35s cubic-bezier(0.31, -0.105, 0.43, 1.59)`,
            '& > *': {
                position: 'relative',
            },
            '&:hover': {
                color: variantColor,
            },
            '&:before': {
                content: '""',
                backgroundColor: hoverColor,
                position: 'absolute',
                transform: `rotate(45deg)`,
                transition: `all 0.35s cubic-bezier(0.31, -0.105, 0.43, 1.59)`,
                width: '120%',
                height: '200px',
                top: '65px',
                left: '-165px',
            },
            '&:hover:before': {
                top: '-80px',
                left: '-5px',
            },
        },
        roundCorners: {
            borderRadius: theme.shape.borderRadius,
        },
        fill: {
            backgroundColor: variantColor,
            color: variantContrast,
            border: `1px solid ${variantColor}`,
        },
        outline: {
            backgroundColor: 'transparent',
            border: '1px solid currentColor',
            color: variantColor,
            '&:hover, &:active, &:focus': {
                color: variantColor,
                backgroundColor: 'transparent',
            },
        },
        'subdued-outline': {
            backgroundColor: 'transparent',
            border: `1px solid ${variantLightColor}`,
            color: variantColor,
            '&:hover, &:active, &:focus': {
                color: variantColor,
            },
        },
        subdued: {
            backgroundColor: variantLightColor,
            color: variantColor,
            border: `1px solid ${variantLightColor}`,
            '&:hover, &:active, &:focus': {
                backgroundColor: variantColor,
                borderColor: variantColor,
            },
        },
        text: {
            background: 'transparent',
            border: '1px solid transparent',
            color: variantColor,
            fontSize: 'inherit',
            fontWeight: 'inherit',
            padding: 0,
            '&:hover, &:active, &:focus': {
                color: variantColor,
                background: 'transparent',
            },
        },
    };
});
const StyledLoadingSpinner = styled(Spinner) `
  font-size: 15px;
  width: 1.2em;
  height: 1.2em;
`;
const CoreButton = React.forwardRef(({ children, to = undefined, className = undefined, id = undefined, type = 'button', disabled = false, loading = false, ariaLabel = undefined, testId = undefined, onClick = undefined, appearance = 'fill', block = false, size = 'md', variant = 'primary', addFancyHover = false, fancyHoverColor, roundCorners = true, target = '_blank', }, ref) => {
    const { classes, cx } = useStyles({ variant, fancyHoverColor, addFancyHover });
    const classNames = cx(classes.core, classes[variant], classes[size], classes[appearance], block && classes.block, disabled && classes.disabled, loading && classes.loading, addFancyHover && classes.fancyHover, roundCorners && classes.roundCorners, className);
    if (to) {
        // Because ReactRouterDom Link el is always relative, check for a full URL, and use an
        // anchor that opens in a new tab (all our links should be using <Link> thanks to SPA)
        return to.startsWith('http') ? (_jsx("a", { ref: ref, className: classNames, id: id, "aria-label": ariaLabel, "data-testid": testId, onClick: onClick, href: to, target: target, rel: "noopener noreferrer", children: children })) : (_jsx(Link, { ref: ref, className: classNames, id: id, "aria-label": ariaLabel, "data-testid": testId, onClick: onClick, to: to, children: children }));
    }
    return (_jsx("button", { ref: ref, className: classNames, type: type, id: id, disabled: disabled, "aria-label": ariaLabel, "data-testid": testId, onClick: onClick, children: loading ? _jsx(StyledLoadingSpinner, { title: "loading" }) : children }));
});
export default CoreButton;
