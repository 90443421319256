import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import loadable from '@loadable/component';
import OutlineButton from '@components/Buttons/OutlineButton';
import { Tooltip, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
const Icon = loadable(() => import('@components/Icons/Icon'));
const useStyles = makeStyles()((theme, { iconColor }) => ({
    gameIcon: {
        width: '20px',
        fill: theme.palette.grey[400],
    },
    container: {
        alignItems: 'center',
        backgroundColor: '#f9f9f9',
        borderRadius: '4px',
        display: 'flex',
        gap: theme.spacing(2),
        padding: theme.spacing(2),
        position: 'relative',
        minHeight: '70px',
    },
    platformBadge: {
        backgroundColor: iconColor,
        borderRadius: '9px',
        position: 'absolute',
        top: theme.spacing(1.2),
        left: theme.spacing(1.2),
        width: '18px',
        height: '18px',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        border: `1px solid #fff`,
        '& svg': {
            maxWidth: '9px',
            maxHeight: '9px',
            fill: '#fff',
        },
    },
    iconContainer: {
        alignItems: 'center',
        backgroundColor: iconColor,
        borderRadius: '4px',
        display: 'flex',
        flex: `0 0 41px`,
        height: '41px',
        justifyContent: 'center',
        padding: theme.spacing(1.25),
        width: '41px',
        '& svg': {
            fill: '#fff',
            width: '20px',
        },
    },
    textContainer: {
        fontFamily: 'Montserrat',
        flexDirection: 'row',
        flex: '1 1 100%',
        maxWidth: 'calc(100% - 103px)',
        overflow: 'hidden',
        '& h3': {
            fontWeight: 700,
            color: theme.palette.grey[900],
            margin: 0,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        '& p': {
            margin: 0,
            marginTop: '4px',
            fontWeight: 600,
            color: theme.palette.grey[400],
            overflow: 'hidden',
            maxWidth: '80%',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    },
    lastChild: {
        color: theme.palette.grey[400],
        flex: '1 1 30px',
        '& svg': {
            fill: theme.palette.grey[400],
            height: '14px',
        },
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.main,
            '& svg': {
                fill: theme.palette.common.white,
            },
        },
    },
}));
const LinkedAccountRow = ({ text, subtext, icon, iconColor, badgeIcon, actionButtonContent, actionButtonStyle, actionButtonLabel, onActionButtonPress, tooltip, }) => {
    const theme = useTheme();
    const iconColorValue = iconColor ? theme.palette[iconColor].main : theme.palette.common.white;
    const { classes, cx } = useStyles({ iconColor: iconColorValue });
    return (_jsx(Tooltip, { title: tooltip || '', children: _jsxs("div", { className: classes.container, children: [badgeIcon && (_jsx("div", { className: classes.platformBadge, children: _jsx(Icon, { icon: badgeIcon, className: classes.gameIcon }) })), _jsx("div", { className: classes.iconContainer, children: _jsx(Icon, { icon: icon, className: classes.gameIcon, title: icon }) }), _jsxs("div", { className: classes.textContainer, children: [_jsx("h3", { "data-notranslate": true, children: text }), _jsx("p", { "data-notranslate": true, children: subtext })] }), _jsx(OutlineButton, { className: cx(classes.lastChild, actionButtonStyle), size: "sm", onClick: onActionButtonPress, label: actionButtonLabel, children: actionButtonContent })] }) }));
};
export default LinkedAccountRow;
