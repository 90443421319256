import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense, lazy } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Container } from '@mui/material';
import { styled, css } from '@mui/system';
import RepeatTheme from '@themes/RepeatTheme';
import { useFeature } from 'flagged';
import TabNavigator from '@components/Navigation/TabNavigator';
import useRemoveWrapperPadding from '@hooks/useRemoveWrapperPadding';
import AccountSocials from './AccountSocials';
import AccountGames from './AccountGames';
const Error404 = lazy(() => import('../Errors/components/Error404'));
const AccountDetails = lazy(() => import('./AccountDetails'));
const AccountTransactions = lazy(() => import('./AccountTransactions'));
const AccountPayments = lazy(() => import('./AccountPayments'));
const AccountOrders = lazy(() => import('./AccountOrders'));
const AccountNotifications = lazy(() => import('./AccountNotifications'));
const AccountScreenContainer = styled('div') `
  background: ${({ theme }) => theme.palette.grey[50]};
`;
const widthRestriction = css `
  max-width: ${RepeatTheme.layout.contentMaxWidth}px !important;
  ${RepeatTheme.breakpoints.down('lg')} {
    padding-left: ${RepeatTheme.layout.contentDesktopSidePadding};
    padding-right: ${RepeatTheme.layout.contentDesktopSidePadding};
  }
`;
const ContainerWrapper = styled(Container) `
  font-family: ${RepeatTheme.typography.fontFamily};
  height: 335px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: url('/img/icons/gearHalf.png');
  background-repeat: no-repeat;
  background-position: bottom right;

  & > h1 {
    color: ${({ theme }) => theme.palette.common.white};
    font-weight: 800;
    font-size: 50px;
    margin: 0;
  }
  & > h3 {
    color: ${({ theme }) => theme.palette.common.white};
    font-weight: '600';
    font-size: '14px';
    margin-top: '28px';
    max-width: '490px';
    line-height: '22px';
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    & > h1 {
      font-size: 38px;
    }
    & > h3 {
      font-size: 12px;
      line-height: 18px;
    }
  }
  ${widthRestriction};
`;
const PageWrapper = styled('div') `
  background: linear-gradient(180deg, #34c2ff 0%, ${({ theme }) => theme.palette.primary.main} 100%);
`;
const TabNavigatiorContainer = styled(Container) `
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0;
  }
  ${widthRestriction};
`;
const AccountScreen = () => {
    const { path } = useRouteMatch();
    const notificationsEnabled = !!useFeature('enable_notifications');
    const tabs = [
        { route: '/account/details', label: 'Account Settings' },
        { route: '/account/transactions', label: 'Transactions' },
        { route: '/account/payments', label: 'Payments' },
        { route: '/account/orders', label: 'Purchases' },
        { route: '/account/game-ids', label: 'Game IDs' },
        { route: '/account/social-accounts', label: 'Social Accounts' },
        { route: '/account/notifications', label: 'Notifications', excludeIf: !notificationsEnabled },
    ].filter((item) => !item.excludeIf);
    useRemoveWrapperPadding(true);
    return (_jsxs(AccountScreenContainer, { children: [_jsx(PageWrapper, { children: _jsxs(ContainerWrapper, { fixed: true, children: [_jsx("h1", { children: "Manage your Account" }), _jsx("h3", { children: "Manage your Personal Information, keep track of your purchases and transaction history, connect Social Media and Game Accounts" })] }) }), _jsx(TabNavigatiorContainer, { fixed: true, children: _jsx(TabNavigator, { tabData: tabs }) }), _jsx(Suspense, { fallback: _jsx("div", {}), children: _jsxs(Switch, { children: [_jsx(Route, { exact: true, path: [path, `${path}/details`], component: AccountDetails }), _jsx(Route, { path: `${path}/transactions`, component: AccountTransactions }), _jsx(Route, { path: `${path}/payments`, component: AccountPayments }), _jsx(Route, { path: `${path}/orders`, component: AccountOrders }), _jsx(Route, { path: `${path}/game-ids`, component: AccountGames }), _jsx(Route, { path: `${path}/social-accounts`, component: AccountSocials }), notificationsEnabled && _jsx(Route, { path: `${path}/notifications`, component: AccountNotifications }), _jsx(Route, { path: "*", component: Error404 })] }) })] }));
};
export default AccountScreen;
