import { useCallback, useEffect, useState } from 'react';
/**
 * Taken from the embla plugin example
 * https://www.embla-carousel.com/plugins/autoplay/
 *
 * @param emblaApi
 * @returns
 */
const useAutoplay = (emblaApi) => {
    const [autoplayIsPlaying, setAutoplayIsPlaying] = useState(false);
    const onAutoplayButtonClick = useCallback((callback) => {
        var _a;
        const autoplay = (_a = emblaApi === null || emblaApi === void 0 ? void 0 : emblaApi.plugins()) === null || _a === void 0 ? void 0 : _a.autoplay;
        if (!autoplay)
            return;
        const resetOrStop = autoplay.options.stopOnInteraction === false ? autoplay.reset : autoplay.stop;
        resetOrStop();
        callback();
    }, [emblaApi]);
    const toggleAutoplay = useCallback(() => {
        var _a;
        const autoplay = (_a = emblaApi === null || emblaApi === void 0 ? void 0 : emblaApi.plugins()) === null || _a === void 0 ? void 0 : _a.autoplay;
        if (!autoplay)
            return;
        const playOrStop = autoplay.isPlaying() ? autoplay.stop : autoplay.play;
        playOrStop();
    }, [emblaApi]);
    useEffect(() => {
        var _a;
        const autoplay = (_a = emblaApi === null || emblaApi === void 0 ? void 0 : emblaApi.plugins()) === null || _a === void 0 ? void 0 : _a.autoplay;
        if (!autoplay)
            return;
        setAutoplayIsPlaying(autoplay.isPlaying());
        emblaApi
            .on('autoplay:play', () => setAutoplayIsPlaying(true))
            .on('autoplay:stop', () => setAutoplayIsPlaying(false))
            .on('reInit', () => setAutoplayIsPlaying(autoplay.isPlaying()));
    }, [emblaApi]);
    return {
        autoplayIsPlaying,
        toggleAutoplay,
        onAutoplayButtonClick,
    };
};
export default useAutoplay;
