import { useEffect } from 'react';
/**
 * @param state Add Game context state
 * @param onSelectedGameServiceChanged Effect invoked when the selected game service is changed
 */
const useSelectedGameServiceChanged = (state, onSelectedGameServiceChanged) => {
    var _a, _b;
    // Set the selected game service detail
    useEffect(() => {
        var _a, _b, _c, _d;
        if (state.availableGameServices &&
            !((_a = state === null || state === void 0 ? void 0 : state.connectGameService) === null || _a === void 0 ? void 0 : _a.gameServiceKey) &&
            ((_b = state === null || state === void 0 ? void 0 : state.connectGameServiceInfo) === null || _b === void 0 ? void 0 : _b.gameServiceKey) !== ((_c = state === null || state === void 0 ? void 0 : state.connectGameService) === null || _c === void 0 ? void 0 : _c.gameServiceKey)) {
            onSelectedGameServiceChanged((_d = state.availableGameServices.gameServices.find((gameService) => { var _a; return (gameService === null || gameService === void 0 ? void 0 : gameService.gameServiceKey) === ((_a = state === null || state === void 0 ? void 0 : state.connectGameServiceInfo) === null || _a === void 0 ? void 0 : _a.gameServiceKey); })) !== null && _d !== void 0 ? _d : null);
        }
    }, [
        JSON.stringify(state === null || state === void 0 ? void 0 : state.availableGameServices),
        (_a = state === null || state === void 0 ? void 0 : state.connectGameServiceInfo) === null || _a === void 0 ? void 0 : _a.gameServiceKey,
        (_b = state === null || state === void 0 ? void 0 : state.connectGameService) === null || _b === void 0 ? void 0 : _b.gameServiceKey,
    ]);
};
export default useSelectedGameServiceChanged;
