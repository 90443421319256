import { makeStyles } from 'tss-react/mui';
import WebFont from 'webfontloader';
WebFont.load({
    google: {
        families: ['Bai Jamjuree:500,600,700'],
    },
    events: false,
    classes: false,
});
const useStyles = makeStyles()((theme) => ({
    pageWrapper: {
        fontFamily: '"Bai Jamjuree", "roboto", "helvetica", "sans-serif"',
    },
    container: {
        margin: 'auto',
        maxWidth: 1300,
        padding: theme.spacing(0, 2),
    },
    /**
     * Generic section headers
     */
    sectionHeader: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        textAlign: 'center',
        '@media (min-width: 740px)': {
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: theme.spacing(20, 0, 10),
            textAlign: 'left',
            '& > *': {
                flex: '0 0 35%',
            },
        },
    },
    sectionHeading: {
        color: theme.palette.grey[900],
        fontSize: 40,
        fontWeight: 'bold',
        letterSpacing: '-5.5%',
        lineHeight: 0.95,
        paddingBottom: theme.spacing(4),
        '@media (min-width: 740px)': {
            fontSize: 61,
            paddingBottom: 0,
        },
    },
    sectionSubHeading: {
        color: theme.palette.grey[500],
        fontSize: 16,
        lineHeight: 1.26,
    },
    /**
     * Navigation
     */
    navWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        '& div': {
            display: 'flex',
            alignItems: 'center',
        },
    },
    logo: {
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        display: 'inline-flex',
        justifyContent: 'center',
        marginRight: theme.spacing(3),
        padding: theme.spacing(2),
        '& img': {
            width: 35,
        },
    },
    /**
     * Banner/Header
     */
    bannerWrapper: {
        color: theme.palette.common.white,
        background: 'url("/img/brand-page/banner.jpg")',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        padding: theme.spacing(0, 2),
    },
    bannerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '100vh',
    },
    bannerContent: {
        padding: theme.spacing(5, 2),
        textAlign: 'center',
        '@media (min-width: 740px)': {
            padding: theme.spacing(2, 0),
            textAlign: 'left',
        },
    },
    bannerTitle: {
        fontSize: 60,
        fontWeight: 'bold',
        lineHeight: 0.9,
        textShadow: '0px 4px 4px rgba(0, 0, 0, 0.09);',
        '@media (min-width: 740px)': {
            fontSize: 80,
            lineHeight: 1,
            maxWidth: '80%',
        },
    },
    bannerText: {
        fontSize: 16,
        lineHeight: 1.42,
        padding: theme.spacing(7, 0),
        textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25);',
        '@media (min-width: 740px)': {
            maxWidth: '35%',
        },
    },
    bannerPreHeading: {
        color: theme.palette.warning.main,
        fontSize: 16,
        fontWeight: 'bold',
    },
    byTheNumbers: {
        alignItems: 'center',
        background: theme.palette.common.white,
        color: theme.palette.grey[900],
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: theme.spacing(5, 2),
        '@media (min-width: 740px)': {
            alignItems: 'flex-start',
            flexDirection: 'row',
            padding: theme.spacing(9),
        },
    },
    byTheNumbersTitle: {
        fontSize: 28,
        fontWeight: 600,
    },
    theNumbers: {
        display: 'flex',
        flexDirection: 'column',
        listStyle: 'none',
        margin: 0,
        padding: 0,
        '@media (min-width: 740px)': {
            flexDirection: 'row',
        },
    },
    displayNumbers: {
        color: theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'column',
        fontSize: 30,
        fontWeight: 'bold',
        padding: theme.spacing(2.5),
        textAlign: 'center',
        '@media (min-width: 740px)': {
            padding: theme.spacing(0, 2.5),
        },
    },
    displayNumbersHeading: {
        color: theme.palette.grey[300],
        fontSize: 14,
        fontWeight: 700,
    },
    startCampaignWrapper: {
        alignItems: 'center',
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(5, 2),
        textAlign: 'center',
        '@media (min-width: 740px)': {
            padding: theme.spacing(10),
            marginTop: theme.spacing(15),
        },
    },
    startCampaignContainer: {
        '@media (min-width: 740px)': {
            maxWidth: '50%',
        },
    },
    startCampaignHeading: {
        color: theme.palette.common.white,
    },
    startCampaignText: {
        color: theme.palette.common.white,
        fontSize: 16,
        padding: theme.spacing(3),
        '@media (min-width: 740px)': {
            padding: theme.spacing(5),
        },
    },
    buttonWrapper: {
        padding: theme.spacing(7, 0, 15),
        textAlign: 'center',
    },
    contactWrapper: {
        color: theme.palette.common.white,
        background: 'radial-gradient(37.6% 260.85% at 69.69% 50.07%, #131415 0%, #292B2D 100%)',
        fontSize: 16,
        padding: theme.spacing(0, 2),
    },
    contactContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(5, 2),
        '@media (min-width: 740px)': {
            flexDirection: 'row',
            padding: theme.spacing(10, 0),
            '& > *': {
                display: 'flex',
                flex: '0 0 50%',
                flexDirection: 'column',
            },
        },
    },
    contactContent: {
        textAlign: 'center',
        paddingBottom: theme.spacing(5),
        '@media (min-width: 740px)': {
            textAlign: 'left',
            paddingBottom: 0,
            '& > p:nth-of-type(1)': {
                padding: theme.spacing(5, 0),
            },
        },
    },
    contactHeading: {
        fontSize: 55,
        lineHeight: 0.9,
        fontWeight: 'bold',
        '@media (min-width: 740px)': {
            fontSize: 72,
        },
    },
    contactForm: {
        alignSelf: 'flex-end',
        background: '#383A3C',
        borderRadius: 6,
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(4),
        width: '100%',
        '@media (min-width: 740px)': {
            width: '75%',
        },
    },
    contactFormLabel: {
        width: '100%',
        fontSize: '12px',
        marginBottom: '0px',
    },
    contactFormTextAreaLabel: {
        marginBottom: theme.spacing(3),
    },
    contactFormTextArea: {
        backgroundColor: '#303233!important',
        border: '1px solid #303233!important',
        borderRadius: theme.shape.borderRadius,
        boxShadow: 'none!important',
        color: theme.palette.grey[100],
        fontSize: '12px',
        fontWeight: 600,
        fontFamily: theme.typography.fontFamily,
        height: 160,
        margin: theme.spacing(2, 0, 0),
        padding: theme.spacing(1.5, 2),
        resize: 'none',
        width: '100%',
        '&::placeholder': {
            color: '#6A6C6D!important',
        },
    },
    contactFormTextInput: {
        backgroundColor: '#303233!important',
        border: '1px solid #303233!important',
        color: theme.palette.grey[100],
        margin: theme.spacing(2, 0, 3),
        padding: theme.spacing(1.5, 2),
        '&:hover, &:focus': {
            backgroundColor: '#303233!important',
            border: '1px solid #303233!important',
            boxShadow: 'none!important',
        },
        '&::placeholder': {
            color: '#6A6C6D!important',
        },
    },
    contactSuccessHeading: {
        fontSize: 29,
        color: theme.palette.common.white,
    },
    contactErrorHeading: {
        fontSize: 29,
        color: theme.palette.common.white,
    },
    button: {
        borderRadius: '0px!important',
        borderWidth: '2px',
        fontWeight: 600,
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
    },
    buttonOutline: {
        background: 'transparent!important',
        borderColor: `${theme.palette.common.white}!important`,
        borderStyle: 'solid',
        marginRight: theme.spacing(3),
        '&:hover, &:active, &:focused': {
            backgroundColor: 'transparent!important',
        },
    },
    buttonWhite: {
        background: theme.palette.common.white,
        borderRadius: '0px!important',
        color: theme.palette.primary.main,
        fontWeight: 600,
        marginRight: theme.spacing(3),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        '&:hover, &:active, &:focused': {
            background: `${theme.palette.common.white}!important`,
        },
    },
    hiddenSM: {
        display: 'none',
        '@media (min-width: 740px)': {
            display: 'inherit',
        },
    },
    featureCards: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: theme.spacing(2),
        '@media (min-width: 740px)': {
            flexDirection: 'row',
            padding: 0,
        },
    },
    card: {
        alignItems: 'center',
        background: theme.palette.common.white,
        border: `1px solid ${theme.palette.grey[100]}`,
        borderRadius: theme.spacing(0.5),
        color: theme.palette.grey[400],
        display: 'flex',
        flexDirection: 'column',
        fontSize: 14,
        padding: theme.spacing(2),
        margin: theme.spacing(2, 0),
        '@media (min-width: 740px)': {
            alignItems: 'flex-start',
            flex: `0 0 calc(25% - ${theme.spacing(4)})`,
            marginLeft: theme.spacing(4),
            padding: theme.spacing(5),
        },
        '& a': {
            color: theme.palette.primary.main,
        },
        '& ul': {
            paddingLeft: 0,
        },
        '&:first-of-type': {
            marginLeft: 0,
        },
        '& > *': {
            paddingTop: theme.spacing(3),
        },
        '& > :first-of-type': {
            paddingTop: 0,
        },
    },
    cardTitle: {
        color: theme.palette.grey[900],
        fontSize: 20,
    },
    carouselRemote: {
        display: 'flex',
        height: 100,
        justifyContent: 'space-between',
        listStyle: 'none',
        padding: theme.spacing(0, 1),
        '@media (min-width: 740px)': {
            height: 150,
            padding: 0,
        },
    },
    carouselRemoteButton: {
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.grey[100]}`,
        borderRadius: 4,
        flex: `0 0 calc(20% - ${theme.spacing(1)})`,
        width: `calc(20% - ${theme.spacing(1)})`,
        display: 'flex',
        '@media (min-width: 740px)': {
            flex: `0 0 calc(20% - ${theme.spacing(4)})`,
            width: `calc(20% - ${theme.spacing(4)})`,
        },
        '& img': {
            margin: 'auto',
            maxWidth: '80%',
            opacity: 0.2,
            '@media (min-width: 740px)': {
                maxHeight: 80,
                maxWidth: 130,
            },
        },
        '&:hover img, &:active img': {
            opacity: 1,
        },
    },
    activeCarouselRemoteButton: {
        background: `linear-gradient(180deg, rgba(240, 240, 240, 0) 0%, rgba(0, 172, 246, 0.27) 100%)`,
        borderBottom: `3px solid ${theme.palette.primary.main}`,
        backgroundRepeat: 'no-repeat',
        '& img': {
            opacity: 1,
        },
    },
    carouselWrapper: {
        marginTop: theme.spacing(4),
    },
    carouselMetadata: {
        display: 'flex',
        color: theme.palette.common.white,
        fontSize: 13,
        fontWeight: 600,
        justifyContent: 'space-between',
        lineHeight: 1.44,
        padding: theme.spacing(1),
        '@media (min-width: 740px)': {
            padding: theme.spacing(0, 6),
            transform: `translateY(${theme.spacing(6)})`,
        },
    },
    carouselPreHeadline: {
        display: 'flex',
        alignItems: 'center',
        '&:before': {
            content: '""',
            background: theme.palette.common.white,
            display: 'block',
            height: 2,
            marginRight: 5,
            width: 32,
        },
    },
    carouselActiveSlideNumber: {
        color: theme.palette.primary.main,
    },
    carouselItem: {
        alignItems: 'center',
        color: theme.palette.common.white,
        display: 'flex',
        flexDirection: 'column-reverse',
        maxWidth: '1076px',
        '& > *': {
            flex: '0 0 100%',
            maxWidth: '100%',
        },
        '@media (min-width: 740px)': {
            flexDirection: 'row',
            padding: theme.spacing(10, 0),
            '& > *': {
                flex: '0 0 50%',
                maxWidth: '50%',
            },
        },
    },
    carouselItemHeadline: {
        fontSize: 42,
        fontWeight: 'bold',
        lineHeight: 0.9,
        textAlign: 'center',
        '@media (min-width: 740px)': {
            fontSize: 52,
            textAlign: 'left',
        },
    },
    carouselItemBody: {
        fontSize: 14,
        lineHeight: 1.44,
        padding: theme.spacing(1, 0),
        textAlign: 'center',
        '@media (min-width: 740px)': {
            padding: theme.spacing(6, 0),
            textAlign: 'left',
        },
    },
    carouselItemNumbers: {
        display: 'flex',
        fontSize: 29,
        lineHeight: 1.44,
        flexWrap: 'wrap',
        '& > div': {
            flex: '0 0 50%',
            padding: theme.spacing(2),
            textAlign: 'center',
            '@media (min-width: 740px)': {
                flex: `0 0 calc(33% - ${theme.spacing(2)})`,
                textAlign: 'left',
                marginRight: theme.spacing(2),
            },
        },
        '& span': {
            display: 'block',
            fontSize: 14,
            color: '#939393',
        },
    },
    carouselItemImage: {
        display: 'flex',
        '& img': {
            margin: 'auto',
            maxHeight: 350,
            maxWidth: '100%',
            '@media (min-width: 740px)': {
                maxHeight: '100%',
            },
        },
    },
    brandCarouselWrapper: {
        background: '#161513',
    },
    capabilitiesCarouselWrapper: {
        background: theme.palette.primary.main,
    },
    capabilitiesCarouselImage: {
        background: 'rgba(0, 86, 123, .15)',
        display: 'flex',
        padding: theme.spacing(3),
        '& img': {
            margin: 'auto',
            maxHeight: 450,
            maxWidth: '100%',
        },
    },
    capabilitiesCarouselItemTickList: {
        listStyle: 'none',
        padding: 16,
        '& li': {
            display: 'flex',
            alignItems: 'center',
            paddingBottom: theme.spacing(2),
        },
        '& li svg': {
            marginRight: theme.spacing(1),
        },
    },
    contactFormErrorMessage: {
        color: theme.palette.error.main,
        fontSize: 12,
        fontWeight: 600,
        paddingBottom: theme.spacing(1),
    },
}));
export default useStyles;
