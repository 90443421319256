import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import ModalContent from '@components/Modals/components/ModalContent';
import LoginServiceIcon from '@components/UI/Icon/LoginServiceIcon';
import Choice from '@components/UI/Choice/Choice';
import { useAddGameContext } from '../context/AddGameProvider';
import useTrackEvent from '@hooks/useTrackEvent';
const useStyles = makeStyles()((theme) => ({
    helpText: {
        textAlign: 'center',
    },
}));
const ContentPUBGServices = ({ className, onGameServiceSelected, stepName }) => {
    const { classes } = useStyles();
    const { availableGameServices } = useAddGameContext();
    const { gameServices } = availableGameServices || {};
    const trackEvent = useTrackEvent();
    const gameServiceOptions = gameServices
        ? gameServices.map((gameService) => ({
            id: gameService.gameServiceKey,
            value: gameService.gameServiceKey,
            label: gameService.name,
            icon: _jsx(LoginServiceIcon, { service: gameService.gameServiceKey, color: "pubg" }),
            paletteName: 'pubg',
        }))
        : [];
    const handleSelect = (selected) => {
        const selectedServiceIndex = gameServices === null || gameServices === void 0 ? void 0 : gameServices.findIndex((gameService) => gameService.gameServiceKey === selected);
        if (gameServices && typeof selectedServiceIndex === 'number' && selectedServiceIndex > -1) {
            const selectedService = gameServices[selectedServiceIndex];
            onGameServiceSelected(selectedService);
            trackEvent({
                type: 'game_linking',
                data: {
                    eventName: 'game linking',
                    label: selectedService.name,
                    moduleName: 'Button',
                    position: selectedServiceIndex,
                },
            });
        }
    };
    return (_jsxs(ModalContent, { className: className, stepName: stepName, verticalAlign: "top", children: [_jsx("h3", { id: "add-game-pubg-select-service", children: "Select game platform" }), _jsx(Choice, { choices: gameServiceOptions, onSelect: handleSelect, selectButtonLabel: "Continue", ariaLabelledBy: "add-game-pubg-select-service" }), _jsx("p", { className: classes.helpText, children: "You can connect multiple accounts" })] }));
};
export default ContentPUBGServices;
