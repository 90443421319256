import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { getTournamentColorPaletteName, getTournamentState } from '@domain/Tournament/helpers/tournamentUtils';
import getTournamentStatusVariant from '@domain/Tournament/helpers/getTournamentStatusVariant';
import getTournamentBoxBannerImage from '@domain/Tournament/helpers/getTournamentBoxBannerImage';
import getTournamentDefaultBanner from '@domain/Tournament/helpers/getTournamentDefaultBanner';
import useGame from '@hooks/API/useGame';
import useAppState from '@hooks/API/useAppState';
import GamesPlayed from '@components/Tournament/GamesPlayed';
import doTrackEvent, { extractTournamentAnalyticsData } from '@components/Analytics/doTrackEvent';
import useTournamentDetailsPageUrl from '@hooks/useTournamentDetailsPageUrl';
import TournamentBoxBanner from '@domain/Tournament/components/TournamentGrid/TournamentBox/TournamentBoxBanner';
import StatusIndicator from '@components/UI/StatusIndicator';
import SROnly from '@components/UI/SROnly';
import Currency from '@components/UI/Currency';
import getTournamentTagsV2 from '@domain/TournamentV2/TournamentTable/getTournamentTags';
import TournamentPhaseBannerSimple from '@domain/Tournament/TournamentDetails/TournamentPhaseBannerAlert/TournamentPhaseBannerSimple';
import { StyledTag, TagContainer } from './style';
import RankRange from './RankRange';
import TournamentBoxPillGameMode from '../../Tournament/components/TournamentGrid/TournamentBox/TournamentBoxPillGameMode';
const TournamentBoxContainer = styled(Link) `
  padding: ${(props) => props.theme.spacing(1.5)} 0;
  display: block;
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[100]};
`;
const TournamentContent = styled('div') `
  display: flex;
  margin-bottom: ${(props) => props.theme.spacing(1)};
`;
const TournamentBanner = styled(TournamentBoxBanner) `
  height: 77px;
  width: 140px;
  border-radius: 4px;
  margin-right: ${(props) => props.theme.spacing(1)};
  padding: ${(props) => props.theme.spacing(1)};
  flex-shrink: 0;
`;
const TournamentTimer = styled(TournamentPhaseBannerSimple) `
  display: block;
  margin-bottom: ${(props) => props.theme.spacing(1)};

  & span {
    font-weight: 600;
    color: ${(props) => props.theme.palette.grey[400]};
    font-size: 10px;
  }
`;
const TournamentEntryDetails = styled('div') `
  display: flex;
`;
const BannerStatus = styled('div') `
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const TournamentStatus = styled(StatusIndicator) `
  margin-left: auto;
  & span {
    width: 13px;
    height: 13px;
    border: 2px solid white;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.35);
    margin: 0;
  }
`;
const TournamentName = styled('h4') `
  font-size: 11px;
  color: ${(props) => props.theme.palette.grey[900]};
  font-weight: 600;
  margin-bottom: ${(props) => props.theme.spacing(1)};
`;
const MetricWrapper = styled('div') `
  margin-right: ${(props) => props.theme.spacing(1)};

  & p:first-of-type {
    font-size: 10px;
    font-weight: 600;
    color: ${(props) => props.theme.palette.grey[400]};
    margin-bottom: ${(props) => props.theme.spacing(0.5)};
  }
`;
const MetricText = styled('p') `
  font-size: 11px;
  font-weight: 600;
  color: ${(props) => props.theme.palette.grey[900]};
  line-height: 11px;
`;
const EntryFeeFree = styled('p') `
  font-size: 11px;
  font-weight: 600;
  color: ${(props) => props.theme.palette.primary.main};
`;
const StyledGamesPlayed = styled(GamesPlayed) `
  & span {
    font-size: 11px;
  }
`;
const TournamentSlots = styled('span') `
  color: ${(props) => props.theme.palette.grey[400]};
`;
const RankModeRequirements = styled('div') `
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > :first-of-type {
    flex: 1;
  }

  & > :last-child {
    justify-content: flex-end;
  }
`;
const TournamentBoxCompact = ({ tournament, className, section, listIndex }) => {
    var _a, _b, _c;
    const appState = useAppState();
    const paletteName = getTournamentColorPaletteName(tournament);
    const gameKey = tournament.score_template.game.game_key;
    const gameData = useGame(gameKey);
    const detailsLink = useTournamentDetailsPageUrl(tournament.id, gameData);
    const tournamentState = getTournamentState(tournament, appState.registrationEndingMinutes);
    const tournamentStatus = getTournamentStatusVariant(tournamentState);
    const tournamentImageUrl = getTournamentBoxBannerImage(tournament);
    const defaultTournamentImageUrl = gameKey ? getTournamentDefaultBanner(gameKey, tournament, true) : '';
    const tournamentCurrency = tournament.currencyType.abbreviation;
    const hasEntered = !!tournament.user_entry;
    const tournamentTags = getTournamentTagsV2(tournament, gameData, true).map((tag) => ({ ...tag, palette: undefined }));
    const isMatchmakingLeaderboard = !!(tournament === null || tournament === void 0 ? void 0 : tournament.leaderboardTemplate);
    const hasRanks = gameKey === 'league_of_legends' || gameKey === 'rocket-league';
    const onBoxClick = () => {
        doTrackEvent({
            ...extractTournamentAnalyticsData(tournament, appState.registrationEndingMinutes),
            type: 'open_tournament',
            section,
            listIndex: listIndex.toString(),
            viewType: 'boxlette',
        });
    };
    const finished = tournamentState === 'closed' || tournamentState === 'finished';
    return (_jsxs(TournamentBoxContainer, { to: detailsLink, onClick: onBoxClick, className: className, children: [_jsxs(TournamentContent, { children: [_jsx(TournamentBanner, { bannerImage: tournamentImageUrl || defaultTournamentImageUrl, paletteName: paletteName, children: _jsx(BannerStatus, { children: _jsx(TournamentStatus, { status: tournamentStatus, children: _jsx(SROnly, { children: `${tournamentState.charAt(0).toUpperCase()}${tournamentState.slice(1)}` }) }) }) }), _jsxs("div", { children: [_jsx(TournamentTimer, { tournament: tournament }), _jsx(TournamentName, { "data-notranslate": true, children: tournament.name }), _jsx(TagContainer, { children: tournamentTags.map((tag) => {
                                    var _a;
                                    return (_jsx(StyledTag, { tagData: tag }, (_a = tag.icon) !== null && _a !== void 0 ? _a : tag.label));
                                }) })] })] }), _jsxs(RankModeRequirements, { children: [_jsx(TournamentBoxPillGameMode, { tournament: tournament, noBorder: true }), hasRanks && (_jsx(RankRange, { lowestRank: tournament.allowed_ranks.lowest_rank, highestRank: tournament.allowed_ranks.highest_rank, iconOnly: true }))] }), _jsxs(TournamentEntryDetails, { children: [_jsxs(MetricWrapper, { children: [_jsx("p", { children: "Entry Fee" }), tournament.entry_fee === 0 ? (_jsx(EntryFeeFree, { children: " Free Entry" })) : (_jsx(MetricText, { children: _jsx(Currency, { withDollarSymbol: true, optimise: true, amount: tournament.entry_fee, currency: tournamentCurrency }) }))] }), !tournament.finished && (_jsxs(MetricWrapper, { children: [_jsx("p", { children: isMatchmakingLeaderboard ? 'Prize Pool Per Leaderboard' : 'Prize Pool' }), _jsx(MetricText, { children: _jsx(Currency, { withDollarSymbol: true, optimise: true, amount: tournament.prize_pot, currency: tournamentCurrency }) })] })), tournament.finished && (_jsxs(MetricWrapper, { children: [_jsx("p", { children: "Earnings" }), _jsx(MetricText, { children: _jsx(Currency, { withDollarSymbol: true, optimise: true, amount: ((_a = tournament.user_entry) === null || _a === void 0 ? void 0 : _a.paidOutValue) && tournament.user_entry.eligible
                                        ? tournament.user_entry.paidOutValue
                                        : 0, currency: tournamentCurrency }) })] })), !hasEntered &&
                        (tournament.slots ? (_jsxs(MetricWrapper, { children: [_jsx("p", { children: "Entrants" }), _jsxs(MetricText, { "data-notranslate": true, children: [tournament.entries_count.toLocaleString(undefined), _jsxs(TournamentSlots, { children: [" / ", tournament.slots.toLocaleString(undefined)] })] })] })) : (_jsxs(MetricWrapper, { children: [_jsx("p", { children: finished ? 'Received prizes' : 'Receive prizing' }), _jsxs(MetricText, { children: [!finished && 'Top', " ", (_b = tournament.maximum_prize_positions) === null || _b === void 0 ? void 0 : _b.toLocaleString(), " Players"] })] }))), hasEntered && (_jsxs(MetricWrapper, { children: [_jsx("p", { children: "Qualification" }), _jsx(MetricText, { "aria-label": "Eligibility", children: _jsx(StyledGamesPlayed, { gamesPlayed: ((_c = tournament.user_entry) === null || _c === void 0 ? void 0 : _c.gamesPlayed) || 0, requiredGames: tournament.score_template.num_games || 1 }) })] }))] })] }));
};
export default TournamentBoxCompact;
