import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import loadable from '@loadable/component';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { CheckCircleIcon, ShieldExclamationIcon, XCircleIcon } from '@heroicons/react/24/solid';
const Sparkle = loadable(() => import('@components/Icons/Sparkle'));
const useStyles = makeStyles()((theme) => ({
    banner: {
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    statusIcon: {
        marginTop: theme.spacing(1),
        fill: theme.palette.primary.main,
    },
    success: {
        fill: theme.palette.success.main,
    },
    error: {
        fill: theme.palette.error.main,
    },
    sparkleTopLeft: {
        margiRight: theme.spacing(1),
    },
    sparkletBottomRight: {
        alignSelf: 'flex-end',
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1),
        transform: 'rotate(180deg)',
    },
}));
export const ModalStatusBanner = ({ status = 'success', className }) => {
    const { classes, cx } = useStyles();
    const icon = useMemo(() => {
        switch (status) {
            case 'pending':
                return _jsx(ShieldExclamationIcon, { className: cx(classes.statusIcon), title: "Pending", width: "138", height: "138" });
            case 'error':
                return _jsx(XCircleIcon, { className: cx(classes.statusIcon, classes.error), title: "Error", width: "95" });
            default:
                return _jsx(CheckCircleIcon, { className: cx(classes.statusIcon, classes.success), title: "Success", width: "95" });
        }
    }, [status]);
    return (_jsxs("div", { className: cx(classes.banner, className), children: [status !== 'pending' && (_jsx(Sparkle, { width: "46", className: cx(classes.sparkleTopLeft, {
                    [classes.success]: status === 'success',
                    [classes.error]: status === 'error',
                }) })), icon, status !== 'pending' && (_jsx(Sparkle, { width: "46", className: cx(classes.sparkletBottomRight, {
                    [classes.success]: status === 'success',
                    [classes.error]: status === 'error',
                }) }))] }));
};
export default ModalStatusBanner;
