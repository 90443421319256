import { useEffect } from 'react';
import firebase from 'firebase/compat/app';
import { useQueryClient } from 'react-query';
import * as Sentry from '@sentry/react';
import doTrackEvent from '@components/Analytics/doTrackEvent';
/**
 * This hook will listen for a Firebase logout event, then clears
 * all user specific data sitting in the React-Query cache.
 *
 * Technically this is not required if we can guarantee all logout events
 * trigger a page refresh - but this may be a dangerous assumption.
 */
const useClearReactQueryCacheOnLogout = () => {
    const queryClient = useQueryClient();
    useEffect(() => {
        const auth = firebase.auth();
        const unsubscribe = auth.onAuthStateChanged((user) => onUserSignInChange(user, queryClient));
        return unsubscribe;
    }, []);
};
export default useClearReactQueryCacheOnLogout;
export const onUserSignInChange = (user, queryClient) => {
    var _a, _b;
    if (user == null) {
        queryClient.clear();
        doTrackEvent({ type: 'logout' });
        Sentry.addBreadcrumb({
            category: 'auth',
            message: `Logout`,
            level: 'info',
        });
        Sentry.setUser(null);
    }
    else {
        doTrackEvent({ type: 'login', userId: user.uid });
        const [first] = (_b = (_a = user.email) === null || _a === void 0 ? void 0 : _a.split('@')) !== null && _b !== void 0 ? _b : ['unknown'];
        Sentry.setUser({ id: user.uid, firebase_uid: user.uid, email: `${first.substring(0, 3)}***@****.com` });
        Sentry.addBreadcrumb({
            category: 'auth',
            message: `Login`,
            level: 'info',
        });
    }
};
