import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import DataLayer from '@sie/global-data-layer';
import { useLocalAppContext } from '@context/LocalAppContext';
import { useUserState } from '@context/UserContext';
import { useFeature } from 'flagged';
import getAdobeLocale from '@domain/Analytics/helpers/getAdobeLocale';
import useSelectedPlatform from './useSelectedPlatform';
import useGamesList from './API/useGamesList';
const getSiteSection = (pathname, gameKeys, platformSlug) => {
    if (pathname.includes('marketplace')) {
        return 'Marketplace';
    }
    const urlIncludesGameKey = !!gameKeys.find((gameKey) => pathname.includes(gameKey));
    if (platformSlug && urlIncludesGameKey) {
        return platformSlug;
    }
    return 'Content';
};
const getSiteVersion = () => {
    const { hostname } = window.location;
    switch (hostname) {
        case 'www.repeat.gg':
        case 'repeat.gg':
            return 'prod';
        case 'www.qa.repeat.gg':
        case 'qa.repeat.gg':
            return 'qa';
        case 'www.dev.repeat.gg':
        case 'dev.repeat.gg':
            return 'dev';
        default:
            return 'local';
    }
};
/**
 * Performs various tracking actions every time the route changes
 */
const useTrackPageView = () => {
    const location = useLocation();
    const isAdobeAnalyticsEnabled = !!useFeature('enable_adobe_analytics');
    const { platformSlug, isLoading: isSelectedPlatformLoading } = useSelectedPlatform();
    const { userDetails, isLoading: isUserStateLoading } = useUserState();
    const { selectedLanguage } = useLocalAppContext();
    const { games, isLoading: isLoadingGamesList } = useGamesList();
    const gameKeys = games.map((game) => game.gameKey);
    const callTrackPageView = () => {
        var _a, _b, _c, _d;
        const primaryCategory = getSiteSection(location.pathname, gameKeys, platformSlug);
        const locale = getAdobeLocale(selectedLanguage.code);
        const env = getSiteVersion();
        const queryParams = new URLSearchParams(location.search);
        const emcidUrlParam = 'emcid';
        const emcid = (_a = queryParams.get(emcidUrlParam)) !== null && _a !== void 0 ? _a : undefined;
        DataLayer.pageview({
            app: {
                env,
                name: 'repeatgg',
                version: (_b = process.env.REACT_APP_VERSION) !== null && _b !== void 0 ? _b : '',
            },
            locale,
            primaryCategory,
            user: {
                id: (_c = userDetails === null || userDetails === void 0 ? void 0 : userDetails.id) !== null && _c !== void 0 ? _c : '',
            },
            emcid,
        });
        // eslint-disable-next-line no-underscore-dangle
        (_d = window._satellite) === null || _d === void 0 ? void 0 : _d.track('repeatgg_page');
    };
    const trackPageView = useCallback(() => {
        if (isAdobeAnalyticsEnabled && !isSelectedPlatformLoading && !isUserStateLoading && !isLoadingGamesList) {
            callTrackPageView();
        }
    }, [
        location.pathname,
        platformSlug,
        isSelectedPlatformLoading,
        isUserStateLoading,
        selectedLanguage.code,
        isLoadingGamesList,
    ]);
    return {
        trackPageView,
    };
};
export default useTrackPageView;
