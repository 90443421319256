import doTrackEvent from '@components/Analytics/doTrackEvent';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
/**
 * @deprecated - Adobe analytics is being implemented via useTrackPageView
 * Performs various tracking actions every time the route changes
 */
const useTrackNavigation = () => {
    const location = useLocation();
    const [previousLocation, setPreviousLocation] = useState();
    useEffect(() => {
        doTrackEvent({ type: 'page_view', pageTitle: document.title, url: location.pathname, previousLocation });
        setPreviousLocation(location.pathname);
    }, [location.pathname]);
};
export default useTrackNavigation;
