import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { styled } from '@mui/system';
import * as React from 'react';
import classnames from 'classnames';
import SROnly from '@components/UI/SROnly';
/**
 * This is the new common input. Please review and
 * extend.
 */
const StyledInput = styled('input') `
  border: 1px solid ${({ theme }) => theme.palette.grey[200]};
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  font-size: 12px;
  font-weight: 600;
  padding: 11px ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.palette.grey[900]};
  width: 100%;
  margin: ${({ theme }) => theme.spacing(2, 0)};
  &::placeholder {
    color: ${({ theme }) => theme.palette.grey[400]};
  }
  &:focus {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.palette.primary.main} !important;
    border: 1px solid ${({ theme }) => theme.palette.primary.main} !important;
  }
  &:enabled:hover {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.palette.grey[300]};
    border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  }
  opacity: 1;
  &.inputError {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.palette.error.main};
    border: 1px solid ${({ theme }) => theme.palette.error.main};
    margin: ${({ theme }) => theme.spacing(2, 0, 1, 0)};
    &:hover {
      margin-bottom: ${({ theme }) => theme.spacing(1)};
    }
    &:focus {
      margin-bottom: ${({ theme }) => theme.spacing(1)};
    }
  }
  &:disabled {
    background: ${({ theme }) => theme.palette.grey[100]};
    color: ${({ theme }) => theme.palette.grey[400]};
    -webkit-text-fill-color: ${({ theme }) => theme.palette.grey[400]};
  }
`;
export const StyledLabel = styled('label') `
  width: 100%;
  font-size: 12px;
  margin-bottom: 0px;
`;
const shouldForwardProp = (prop) => prop !== 'textAlign';
export const StyledErrorSpan = styled('span', { shouldForwardProp }) `
  opacity: 0;
  transition: opacity 0.3s;
  color: ${({ theme }) => theme.palette.error.main};
  &.showError {
    display: block;
    opacity: 1;
    font-size: 11px;
    font-weight: 600;
    text-align: ${({ textAlign }) => textAlign};
    padding-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;
const TextInput = React.forwardRef(({ id = 'generic-modal-input', label, value, type = 'text', onChange, placeholder, error = false, errorMessage = '', className, disabled = false, isLabelSROnly = false, ...inputProps }, ref) => {
    const labelComponent = isLabelSROnly ? _jsx(SROnly, { children: label }) : label;
    const errorMessageIsString = typeof errorMessage === 'string';
    const textAlign = errorMessageIsString ? (errorMessage.length > 120 ? 'center' : 'right') : 'right';
    return (_jsx(_Fragment, { children: _jsxs(StyledLabel, { htmlFor: id, children: [labelComponent, _jsx(StyledInput, { ...inputProps, ref: ref, id: id, name: id, value: value, type: type, className: classnames({
                        inputError: error,
                        disabledInput: disabled,
                    }, className), placeholder: placeholder, onChange: onChange, disabled: disabled }), _jsx(StyledErrorSpan, { role: "alert", textAlign: textAlign, className: classnames({
                        showError: error,
                    }), children: error ? errorMessage : '' })] }) }));
});
export default TextInput;
