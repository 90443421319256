import { jsx as _jsx } from "react/jsx-runtime";
import { useReducer, createContext, useContext, useEffect } from 'react';
import { useUserState } from '@context/UserContext';
import useGamesList from '@hooks/API/useGamesList';
import getGameServiceRegions from '@api/getGameServiceRegions';
import getAddGameCallbackParams from '@helpers/getAddGameCallbackParams';
import AddGameStepsReducer from './AddGameStepsReducer';
import getDefaultAddGameContextState from '../helpers/getDefaultAddGameContextState';
import { setSelectedGameAction, setRequireAgePromptAction, setIsOverConsentAgeAction, setAvailableGameServicesAction, resetConnectSelectionAction, setIsLoadingAction, setIsProviderReady, setConnectGameServiceAction, setConnectRegionAction, setConnectPlatformAction, } from './AddGameStepsActions';
import useSelectGameBasedOnGameKey from '../hooks/useSelectGameBaseOnGameKey';
import useHandleAddGameEvents from '../hooks/useHandleAddGameEvents';
import useSelectedGameChanged from '../hooks/useSelectedGameChanged';
import useAddGameProviderIsReady from '../hooks/useAddGameProviderIsReady';
import useSelectedGameServiceChanged from '../hooks/useSelectedGameServiceChanged';
import useDefaultPlatformChanged from '../hooks/useDefaultPlatformChanged';
import useSelectedRegionChanged from '../hooks/useSelectedRegionChanged';
export const AddGameContext = createContext({
    ...getDefaultAddGameContextState(),
    oauthCallbackParams: {},
    dispatch: (x) => x,
});
const { Provider } = AddGameContext;
export const AddGameProvider = ({ children, selectedGameKey, onAddGameError, onAddGameSuccess, oauthCallbackParams = {}, }) => {
    var _a, _b;
    const user = useUserState();
    // Get a list of available games
    const { games } = useGamesList();
    const addGameStatus = getAddGameCallbackParams();
    const preselectedGameKey = selectedGameKey || (addGameStatus === null || addGameStatus === void 0 ? void 0 : addGameStatus.gameKey);
    const preselectedGame = preselectedGameKey ? games.find((game) => game.gameKey === preselectedGameKey) : null;
    // Get initial add game state
    const [state, dispatch] = useReducer(AddGameStepsReducer, {
        ...getDefaultAddGameContextState(),
        selectedGame: preselectedGame !== null && preselectedGame !== void 0 ? preselectedGame : null,
        gameConnectionStatus: (_a = addGameStatus === null || addGameStatus === void 0 ? void 0 : addGameStatus.status) !== null && _a !== void 0 ? _a : null,
        connectGameRegionInfo: addGameStatus === null || addGameStatus === void 0 ? void 0 : addGameStatus.region,
        connectGameServiceInfo: addGameStatus === null || addGameStatus === void 0 ? void 0 : addGameStatus.gameService,
        fetched: addGameStatus !== null
            ? addGameStatus.isSuccess
                ? {
                    isError: false,
                    response: null,
                }
                : {
                    isError: true,
                    response: null,
                    errorMessage: (_b = addGameStatus.errorMessage) !== null && _b !== void 0 ? _b : 'Something went wrong',
                }
            : undefined,
    });
    /**
     * This function is used to fetch game services regions data for the selected game from the API.
     * @param gameKey string - the game key that identifies the game.
     */
    const fetchGameServiceRegionsData = async (gameKey) => {
        const gameServiceResponse = await getGameServiceRegions(gameKey);
        dispatch(setAvailableGameServicesAction(gameServiceResponse));
    };
    useSelectGameBasedOnGameKey((selectedGame) => {
        dispatch(setSelectedGameAction(selectedGame));
    }, selectedGameKey);
    useSelectedGameServiceChanged(state, (gameService) => {
        dispatch(setConnectGameServiceAction(gameService));
    });
    useDefaultPlatformChanged(state, (platform) => {
        dispatch(setConnectPlatformAction(platform));
    });
    useSelectedRegionChanged(state, (region) => {
        dispatch(setConnectRegionAction(region));
    });
    useHandleAddGameEvents({
        state,
        onAddGameSuccess,
        onAddGameError,
        callback: () => dispatch(setIsLoadingAction(false)),
    });
    useSelectedGameChanged({
        state,
        userDetails: user.userDetails,
        onUserIsOfAge: () => {
            dispatch(setRequireAgePromptAction(false));
            dispatch(setIsOverConsentAgeAction(true));
        },
        onSelectedGameChanged: async (gameKey) => {
            dispatch(setIsLoadingAction(true));
            // Fetch new service region information
            await fetchGameServiceRegionsData(gameKey);
            // Refresh connect selections
            dispatch(resetConnectSelectionAction());
            dispatch(setIsLoadingAction(false));
        },
    });
    useAddGameProviderIsReady(() => {
        dispatch(setIsProviderReady(true));
    }, state);
    return (_jsx(Provider, { value: {
            ...state,
            oauthCallbackParams,
            dispatch,
        }, children: children }));
};
export const useAddGameContext = () => {
    const context = useContext(AddGameContext);
    if (context === undefined) {
        throw new Error('useAddGameContext must be used within a AddGameProvider');
    }
    return context;
};
export const useOnAddGameContextReady = (callback) => {
    const context = useAddGameContext();
    useEffect(() => {
        if (context.isProviderReady) {
            callback(context);
        }
    }, [context.isProviderReady]);
};
export default AddGameProvider;
