import { styled } from '@mui/material/styles';
import CheckboxField from '@components/Fields/CheckboxField';
export const UnderAgeMessage = styled('p') `
  color: ${({ theme }) => theme.palette.grey[800]};
`;
export const StyledCheckbox = styled(CheckboxField) `
  align-items: center;
  border: 1px solid #dfdfdf;
  border-radius: ${({ theme }) => theme.spacing(0.5)};
  column-gap: ${({ theme }) => theme.spacing(1.5)};
  line-height: unset !important;
  padding: ${({ theme }) => theme.spacing(2)};

  input[type='checkbox'] {
    aspect-ratio: 1;
    height: 16px;
    line-height: inherit;
    margin: 0;
  }
`;
export const CheckboxMessage = styled('p') `
  color: ${({ theme }) => theme.palette.error.main};
  font-size: 11px;
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
`;
export const Stack = styled('section') `
  display: flex;
  flex-direction: column;
`;
export const ButtonStack = styled(Stack) `
  row-gap: ${({ theme }) => theme.spacing(2)};
`;
export const AgeRestrictionIcon = styled('div') `
  align-items: center;
  aspect-ratio: 1;
  border-radius: 9999px;
  border: 8px solid ${({ theme }) => theme.palette.grey[100]};
  color: #000;
  display: inline-flex;
  font-size: 32px;
  justify-content: center;
  width: 106px;
  margin: auto auto ${({ theme }) => theme.spacing(2.5)};
  max-width: 106px;
  width: 100%;
`;
