import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Divider } from '@mui/material';
import loadable from '@loadable/component';
import { makeStyles } from 'tss-react/mui';
import CrossCloseIcon from '@components/UI/CrossCloseIcon';
import GenericModal from '@components/Modals/GenericModal';
import ModalContent from '@components/Modals/components/ModalContent';
import ModalBody from '@components/Modals/components/ModalBody';
import CoreButton from '@components/Buttons/CoreButton';
const ErrorFillIcon = loadable(() => import('@components/Icons/ErrorFillIcon'));
const useStyles = makeStyles()((theme) => ({
    closeBtn: {
        background: 'transparent',
        border: 'none',
        height: '30px',
        position: 'absolute',
        right: '10px',
        top: '10px',
        width: '30px',
    },
    errorIcon: {
        width: '95px',
    },
    title: {
        color: theme.palette.grey[600],
        fontSize: '18px',
        fontWeight: 'bold',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    text: {
        color: theme.palette.grey[500],
        '& strong': {
            color: theme.palette.grey[600],
        },
        '&.error': {
            color: theme.palette.error.main,
        },
    },
    divider: {
        margin: theme.spacing(3, 0),
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(2),
    },
}));
/**
 * Modal for notifying a user of an error with a corresponding error code.
 *
 */
const ErrorCodeModal = ({ onClose, title, content, errorCode, showContactSupport = true, testId, }) => {
    const { classes, cx } = useStyles();
    return (_jsxs(GenericModal, { show: true, size: "small", "data-testid": testId, children: [_jsx(CrossCloseIcon, { action: onClose, className: classes.closeBtn }), _jsx(ModalBody, { children: _jsxs(ModalContent, { align: "center", children: [_jsx(ErrorFillIcon, { className: classes.errorIcon }), _jsx("h2", { className: classes.title, children: title }), content && typeof content === 'string' && _jsx("p", { className: classes.text, children: content }), content && typeof content !== 'string' && content, showContactSupport && (_jsxs("p", { className: classes.text, children: ["Try again or", ' ', _jsx("span", { "data-ignore": true, children: _jsx("strong", { children: _jsx("a", { href: "https://support.repeat.gg/hc/en-us/requests/new", rel: "noopener noreferrer", target: "_blank", children: "contact support" }) }) }), errorCode && ' with the error code', "."] })), errorCode && (_jsxs(_Fragment, { children: [_jsx(Divider, { className: classes.divider }), _jsx("p", { className: classes.text, children: "Error Code" }), _jsx("p", { className: cx(classes.text, 'error'), children: errorCode })] })), _jsx(CoreButton, { onClick: onClose, className: classes.button, block: true, children: "Close" })] }) })] }));
};
export default ErrorCodeModal;
