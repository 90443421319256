import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import loadable from '@loadable/component';
import { makeStyles } from 'tss-react/mui';
import ButtonPopper from '@components/UI/ButtonPopper';
import NavItem from './NavItem';
import OtherMenu from './OtherMenu';
import useSelectedPlatform from '@hooks/useSelectedPlatform';
const Ellipsis = loadable(() => import('@components/Icons/Ellipsis'));
const useStyles = makeStyles()((theme, _params, classes) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: theme.typography.fontFamily,
        borderLeft: `1px solid ${theme.palette.grey[100]}`,
        paddingLeft: '7px',
    },
    navigationLink: {
        color: theme.palette.grey[400],
        marginLeft: '3px',
        marginRight: '3px',
        paddingRight: '10px',
        paddingLeft: '10px',
        height: '55px',
        whiteSpace: 'nowrap',
        '& span': {
            fontSize: '12px',
            fontWeight: 600, // override MUI
        },
        '&.selected': {
            color: theme.palette.primary.main,
        },
        '&:hover': {
            backgroundColor: '#F6F7FA',
            borderRadius: '4px',
            height: '35px',
        },
    },
    button: {
        whiteSpace: 'nowrap',
        borderRadius: '0px',
        height: '55px',
        [`&:hover .${classes.ellipsisIcon}`]: {
            fill: theme.palette.primary.main,
        },
        marginLeft: '3px',
        marginRight: '3px',
        paddingRight: '10px',
        paddingLeft: '10px',
    },
    ellipsisIcon: {
        fill: theme.palette.grey[400],
    },
    popper: {
        zIndex: theme.zIndex.appBar + 2,
    },
}));
/**
 * A supplimentary menu to assist users with site-wide navigation
 */
const NavigationSite = () => {
    const { classes } = useStyles();
    const { platformSlug } = useSelectedPlatform();
    return (_jsxs("div", { className: classes.container, children: [_jsx(NavItem, { className: classes.navigationLink, to: `${platformSlug ? `/${platformSlug}` : ''}/compete`, linkText: "Compete" }), _jsx(NavItem, { className: classes.navigationLink, to: "/marketplace", linkText: "Marketplace" }), _jsx(NavItem, { className: classes.navigationLink, href: "/content", linkText: "News" }), _jsx(ButtonPopper, { buttonProps: {
                    variant: 'white',
                    className: classes.button,
                    'aria-controls': 'simple-menu',
                    'aria-haspopup': 'true',
                    ariaLabel: 'Other Links',
                    children: _jsx(Ellipsis, { className: classes.ellipsisIcon }),
                }, className: classes.popper, children: _jsx(OtherMenu, {}) })] }));
};
export default NavigationSite;
