import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@components/Buttons/Button';
import TextInput from '@components/Fields/TextInput';
import Choice from '@components/UI/Choice/Choice';
import RealMoney from '@components/UI/RealMoney';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
    invalidChoice: {
        opacity: 0.5,
    },
    customWrapper: {
        marginTop: theme.spacing(1),
    },
    errorText: {
        color: theme.palette.error.main,
        display: 'block',
        opacity: 1,
        fontSize: '11px',
        fontWeight: 600,
        textAlign: 'right',
        paddingBottom: theme.spacing(1),
    },
}));
const SelectTransactionAmount = ({ palette, minAmount, maxAmount, onAmountSelected, amountsArray, className, isLoading, }) => {
    const [isCustom, setIsCustom] = useState(false);
    const { classes } = useStyles();
    const [error, setError] = useState(null);
    const [customValue, setCustomValue] = useState('');
    const hasMaxAmount = typeof maxAmount === 'number' && maxAmount >= 0;
    const validateValue = (value) => {
        if (value.length === 0) {
            return 'Please specify a transaction amount';
        }
        if (Number.isNaN(Number(value))) {
            return 'Invalid transaction amount';
        }
        const numberValue = Number(value);
        if (minAmount >= 0 && numberValue < minAmount) {
            return `Unable to select less than $${minAmount}`;
        }
        if (isLoading !== undefined && isLoading) {
            return 'Please wait while we load your available funds.';
        }
        if (hasMaxAmount && maxAmount >= 0 && numberValue > maxAmount) {
            return `Unable to select more than $${maxAmount}`;
        }
        return null;
    };
    const onOptionSelected = (nextValue) => {
        if (nextValue === 'custom') {
            setIsCustom(true);
            setError(null);
        }
        else {
            const validationError = validateValue(nextValue);
            if (validationError) {
                setError(validationError);
            }
            else {
                setError(null);
                onAmountSelected(Number(nextValue));
            }
        }
    };
    const onCustomTextChange = (ev) => {
        setCustomValue(ev.target.value);
        setError(null);
    };
    const onCustomOptionSubmitted = () => {
        const validationError = validateValue(customValue);
        if (validationError) {
            setError(validationError);
        }
        else {
            setError(null);
            onAmountSelected(Number(customValue));
        }
    };
    const amounts = amountsArray.map((value) => ({
        id: value.toString(),
        value: value.toString(),
        label: !hasMaxAmount || (hasMaxAmount && (maxAmount === 0 || maxAmount >= value))
            ? 'Select'
            : 'Insufficient  Credit',
        icon: _jsx(RealMoney, { amount: value }),
        paletteName: palette,
        disabled: hasMaxAmount && (maxAmount === 0 || maxAmount < value),
        className: hasMaxAmount && (maxAmount === 0 || maxAmount < value) ? classes.invalidChoice : '',
    }));
    const selectableAmounts = [
        ...amounts,
        {
            id: '5',
            value: 'custom',
            label: 'Set Custom',
            icon: _jsx(_Fragment, { children: "Set" }),
            paletteName: palette,
        },
    ];
    return (_jsxs("div", { className: className, children: [_jsx(Choice, { choices: selectableAmounts, onSelect: onOptionSelected }), isCustom && (_jsxs("div", { className: classes.customWrapper, children: [_jsx(TextInput, { id: "transaction-amount", label: "Enter transaction amount", value: customValue, onChange: onCustomTextChange, type: "number", placeholder: "Transaction amount", error: !!error, errorMessage: error }), _jsx(Button, { variant: "palette", paletteName: palette, block: true, onClick: onCustomOptionSubmitted, children: "Next" })] })), !isCustom && error && (_jsx("span", { role: "alert", className: classes.errorText, children: error }))] }));
};
export default SelectTransactionAmount;
