import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from '@mui/material/styles';
const StyledWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '50px',
    '& > div': {
        marginBottom: '21px',
        '&:last-child': {
            marginBottom: 0,
        },
    },
    '& .row > div': {
        [theme.breakpoints.down('xs')]: {
            marginBottom: '21px',
            '&:last-child': {
                marginBottom: 0,
            },
        },
    },
    '& .textField': {
        '& label': {
            marginBottom: '18px',
            fontWeight: 600,
        },
        '& .input-group': {
            backgroundColor: '#f9f9f9',
        },
    },
    '& .dob': {
        '& label': {
            marginBottom: '18px',
            fontWeight: 600,
        },
        '& .row > div': {
            marginBottom: 0,
        },
    },
    '& .input-group': {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '4px',
        '& input.form-control': {
            width: '100%',
            fontWeight: 500,
            background: 'none',
            paddingLeft: '18px',
            border: '1px solid transparent !important',
            height: '50px',
        },
        '&.error': {
            backgroundColor: 'rgba(231, 32, 32, 0.03)',
            border: '1px solid #FF5A50 !important',
        },
    },
    '& .helperText': {
        textAlign: 'right',
        fontWeight: 600,
        marginTop: '9px',
        '& i': {
            marginLeft: '6px',
            fontWeight: 600,
        },
        '&.error': {
            color: '#FF5A50',
        },
    },
}));
const TextFieldWrapper = ({ children }) => {
    return _jsx(StyledWrapper, { children: children });
};
export default TextFieldWrapper;
